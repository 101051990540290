import React from "react";

export default function MixerContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Flux Mixers</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          FLUX offers a complete range of mixers for agitating, mixing, stirring
          or homogenizing operations within containers of 30 to 30.000 Litres.
          The complete range of mixers is based on a modular design, allowing
          the user to select the individual component parts to meet his specific
          requirements.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/flux/BP-800x500-2.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Anyone who wants to disperse, emulsify, homogenise, chill, dissolve,
            mix, neutralise, stir, circulate or exchange heat efficiently is
            faced with a demanding task ‒ from a technical and physical point of
            view. The mixing effect that flows develop depends on the shape of
            the container, the material and of course the type of mixer.
          </p>
        </div>
      </div>
      <div>
        <h2>Mixer Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Drive motors
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                All drive motors are manufactured according to IEC standards.
                FLUX mixers do not require special drive motors - as required on
                many other mixing units.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Gearmotors
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Gearboxes as a matched unit to the drive motor are also
                manufactured according to IEC standards.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Mixing shafts
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Mixing shafts are available in stainless steel 316 L (S), as
                well as with PVDF or PE coating. This offers a wide range of
                compatibility with various liquids.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Mixing blades
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Mixing blades are available in PVDF and PP to perfectly suit
                high as well as low speed mixers.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sealing flanges
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                On the motor side sealing flanges with radial shaft seals are
                available as a cost-effective solution.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Connections
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Standardized connections ensure that all component parts within
                a model size are comptatible to each other. This allows to make
                supplements or extensions to existing FLUX mixers at any time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
