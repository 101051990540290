import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PumpAccessoryContent from "./PrimaryFluidPumpAccessoryContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/pump-accessories/primary-fluid"
            component={PumpAccessoryContent}
          />
          <Route path="/products/pump-accessories">
            <Redirect to="/products/pump-accessories/primary-fluid" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
