import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HaywardContent from "./HaywardStrainerContent";
import HaywardValveContent from "./HaywardValveContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/suppliers/hayward/strainers"
            exact
            component={HaywardContent}
          />
          <Route
            path="/suppliers/hayward/valves"
            exact
            component={HaywardValveContent}
          />
          <Route path="/suppliers/hayward">
            <Redirect to="/suppliers/hayward/strainers" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
