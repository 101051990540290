import React from "react";
import { NavLink } from "react-router-dom";

class SupplierSidebar extends React.Component {
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12 rightSidebar">
          <div className="all_services service-side-btn m-b30 site-bg-secondry p-a20 text-white">
            <h2 className="wt-title">Products</h2>
            <ul>
              <li>
                <NavLink to={"/suppliers/caster/pumps"}>Pumps</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SupplierSidebar;
