import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Burks Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Burks Pumps offers extraordinarily versatile lines of end suction
          standard and self-priming centrifugal pumps, in-line centrifugal pumps
          and regenerative turbine pumps. All Burks Pumps are offered in either
          close-coupled or frame-mounted designs. Materials availability
          includes all bronze, all iron, bronze fitted cast iron and 316 SS.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/burks/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Pump application is equally versatile from process plant to general
            plant service. Meeting OEM needs with special purpose-built pumps is
            a Burks' strength.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>Reliable products, engineered to last.</p>
            <div className="p-t15 text-uppercase">
              <strong>Burks</strong>
              <span>cranepumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Condensate Return Units
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Burks condensate return units are designed for automatic return
                of hot water condensation from radiators, coils, etc. to low and
                high pressure boilers, or for return of water and other liquids
                to the overhead tanks of industrial gravity circulating systems.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Burks Self-Priming Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Burks self-priming pumps are designed to meet a broad range of
                general and special industrial applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  End-Suction Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                These end-suction pumps are heavy duty, general purpose
                centrifugal pumps with heavy wall castings suitable for many
                commercial and industrial applications such as condenser,
                chilled, and hot water systems in HVAC and process secondary
                pumping.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Regenerative Turbine Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Burks Regenerative Turbine pumps unique design is engineered for
                high pressure, low flow applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
