import React from "react";
import { NavLink } from "react-router-dom";

const projects = [
  {
    image: require("./../../images/industrial-pumps.png"),
    title: "Industrial Pumps",
    description:
      "High performance pumps for multiple insdustries and use-cases!",
    ref: "/products/pumps",
  },
  {
    image: require("./../../images/boiler-level-controls.png"),
    title: "Boiler & Level Controls",
    description:
      "Efficient boilers that prevent rapid burner and pump cycling.",
    ref: "/products/boiler-controls",
  },
  {
    image: require("./../../images/couplings.png"),
    title: "Couplings",
    description: "Manufactured to the most stringent international standards.",
    ref: "/products/couplings",
  },
  {
    image: require("./../../images/flow-meters.png"),
    title: "Flow Meters",
    description:
      "A wide range of flowmeters for both liquid and gas applications.",
    ref: "/products/flow-meters",
  },
  {
    image: require("./../../images/industrial-mixers.png"),
    title: "Industrial Mixers",
    description:
      "A complete range of mixers for agitating, stirring or homogenizing operations.",
    ref: "/products/mixers",
  },
  {
    image: require("./../../images/metering-pump-accessories.png"),
    title: "Metering Pump Accessories",
    description:
      "Committed to delivering high-quality, durable pump accessories.",
    ref: "/products/pump-accessories",
  },
  {
    image: require("./../../images/pressure-instruments.png"),
    title: "Pressure Instruments",
    description:
      "Pressure Gauges, Pressure Sensors, Pressure Switches, and more are available.",
    ref: "/products/pressure-instruments",
  },
  {
    image: require("./../../images/strainers.png"),
    title: "Strainers",
    description:
      "Basket Strainers, Duplex Plastic Strainers, Plastic Y Strainers and more!",
    ref: "/products/strainers",
  },
  {
    image: require("./../../images/valves.png"),
    title: "Valves",
    description:
      "Top Valve, back pressure and pressure relief valves, in PVC, PP, CPVC, and TFE.",
    ref: "/products/valves",
  },
];

class Products extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white">
          <div className="container">
            {/* GALLERY CONTENT START */}
            <div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}
                >
                  <div className="project-img-effect-1">
                    <img src={item.image} alt="" />
                    <div className="wt-info">
                      <h3 className="wt-tilte m-b10 m-t0">{item.title}</h3>
                      <p>{item.description}</p>
                      <NavLink to={item.ref} className="site-button-link">
                        View All
                      </NavLink>
                    </div>
                    <a href={item.image} className="mfp-link">
                      <i className="fa fa-search-plus" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
      </>
    );
  }
}

export default Products;
