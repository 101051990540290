import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import StrainerContent from "./HaywardStrainerContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/strainers/hayward"
            component={StrainerContent}
          />
          <Route path="/products/strainers">
            <Redirect to="/products/strainers/hayward" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
