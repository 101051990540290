import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import BurkertValveContent from "./BurkertValveContent";
import HaywardValveContent from "./HaywardValveContent";
import HaysValveContent from "./HaysValveContent";
import PrimaryFluidValveContent from "./PrimaryFluidValveContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/valves/burkert"
            component={BurkertValveContent}
          />
          <Route
            path="/products/valves/hayward"
            component={HaywardValveContent}
          />
          <Route path="/products/valves/hays" component={HaysValveContent} />
          <Route
            path="/products/valves/primary-fluid"
            component={PrimaryFluidValveContent}
          />
          <Route path="/products/valves">
            <Redirect to="/products/valves/burkert" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
