import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Deming Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Deming manufactures one of the most complete and diversified
          industrial lines of pumping equipment. Renowned for outstanding
          durability, efficiency and low-maintenance, Deming’s product range
          includes end suction general service and process pumps, ANSI
          horizontal and vertical inline process pumps, horizontally split
          double suction single and two stage pumps, submersible motor and
          vertical column sump pumps, and horizontal and vertical solids
          handling pumps.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/deming/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            They can be applied wherever fluids must be reliably and efficiently
            transferred -- from general industry to nuclear applications.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Many Deming pumps operate continuously for over 30 years before
              being replaced.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Deming</strong>
              <span>cranepumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Applications of Deming Products</h2>
        <p>
          The Deming brand was the first pump line manufactured by Crane Pumps &
          Systems and offers a full line of highly regarded municipal and
          industrial pumps today.
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Wastewater and Sewage</li>
              <li>Industrial</li>
              <li>Clean Water</li>
              <li>Dewatering</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/deming/deming-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  End Suction ANSI Process Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                All purpose centrifugal pump designed to B75.1 specifications,
                heavy duty service for liquid ranging from water and light
                hydrocarbons to heavy slurries.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  End Suction Centrigual Pumps (Frame Mounted /Closed Coupled)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming End Suction (Frame Mounted /Closed Coupled) versatile,
                low cost centrifugal pumps are engineered for use on most phases
                of liquid handling.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Inline ANSI Chemical And Process Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming Vertical ANSI Inline Chemical and Process Pumps space
                saving, back pull-out design allows versatile applications in a
                wide range of industries.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Horizontal And Vertical Dry Pit Sewage Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming horizontal and vertical dry pit sewage pumps are compact
                in design to meet a wide range of service conditions.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Horizontal Split Case Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming horizontal split-case single stage double suction,
                general purpose pump, designed for continuous service handling
                both hot and cold fluids.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Sump Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming General Service Vertical Sump Pumps are designed to
                handle various fluids from drainage water or any liquid waste
                containing small, non-fibrous solids to larger industrial
                processes.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Process Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Deming Vertical Process pumps are space saving, always primed,
                with cantilever shaft, no submerged bearings, and broad capacity
                range.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
