import React from "react";

export default function PumpAccessoryContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Primary Fluid Systems</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Primary Fluid Systems begin in 1991, as two people building systems
          for local applications. Since that time, we have left system-building
          to the pros, and focused on what we do best: manufacture and sell
          metering pump accessories for a variety of industries. Our innovative
          and patented designs ensure that we provide the best product for your
          specific application. Our company has expanded to nearly 20 people,
          and our products are used in the Americas, Europe, Africa, and Asia.
          We serve customers in diverse industries, including water and
          wastewater treatment, oil and gas, pharmaceutical, and food and
          beverage. With a large inventory available, we can meet our customers'
          needs for specific product configurations in a short time frame.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/primary-fluid/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            With 25 years under our belt, and many of our valves lasting in the
            field for more than 15 years, our products outlast the competition.
            Each product has specific innovations that differentiate it from our
            competitors'. Additionally, we are uniquely able to customize our
            products based on customer specification. We pride ourselves on
            having qualified Technical Sales Representatives who can assist our
            customers in selecting the best product for their application, and
            ensure chemical compatibility. Contact us with any inquiries and we
            will be happy to assist.
          </p>
        </div>
      </div>
      <div>
        <h2>Pump Accessories</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  ACCUDRAW Calibration Cylinders
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                <b>
                  <i>Standard Features</i>
                </b>
                <br />
                - Translucent
                <br />
                - Chemical resistant
                <br />
                - Break resistant
                <br />
                - Threaded or socket
                <br />
                - Colored graduations and lettering
                <br />
                - PVC has dual scale USGPH & ml
                <br />
                - PVC sizes 100 - 20000 ml
                <br />
                - POLY sizes 100 - 4000 ml
                <br />
                - POLY meets ISO standards
                <br />
                - Custom sizes and other materials (acrylic, glass) on request
                <br />
                <b>Available in PVC or Polypropylene</b>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  ACCU-PULSE Pulsation Dampeners
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <b>
                  <i>Standard Features</i>
                </b>
                <br />
                - Lightweight, compact design
                <br />
                - 150 psi rating
                <br />
                - Easy in-line maintenance
                <br />
                - 2 year warranty
                <br />
                - 3/8", 3/4" and 1" pipe connections; 10 & 85 Cubic in.
                <br />
                - PP, PVC, PVDF, 316 S/S, Alloy 20 and Hastelloy C<br />- High
                Pressure Dampeners to 4000 psi available
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Corporation Stops
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <b>
                  <i>PFS Corporation Stops</i>
                </b>
                <br />
                - Isolation valve allows for ease of maintenance
                <br />
                - Available in 6 materials of construction
                <br />
                - Wetted components have comparable or greater chemical
                resistance than quill construction material
                <br />
                - Standard and custom lengths available
                <br />- Connection in NPT, Metric or flanged
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Injection Quills
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <b>
                  <i>Standard Features</i>
                </b>
                <br />
                - Isolation valve allows for ease of maintenance
                <br />
                - Available in 6 materials of construction
                <br />
                - Wetted components have comparable or greater chemical
                resistance than quill construction material
                <br />
                - Standard and custom lengths available
                <br />- Connection in NPT, Metric or flanged
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
