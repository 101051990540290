import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const logos = [
  { image: require("./../../images/client-logo/amt.png") },
  { image: require("./../../images/client-logo/apollo.png") },
  { image: require("./../../images/client-logo/armstrong.png") },
  { image: require("./../../images/client-logo/aro.png") },
  { image: require("./../../images/client-logo/aw-lake.png") },
  { image: require("./../../images/client-logo/barnes.png") },
  { image: require("./../../images/client-logo/bell-gossett.png") },
  { image: require("./../../images/client-logo/burkert.png") },
  { image: require("./../../images/client-logo/burks.png") },
  { image: require("./../../images/client-logo/flux.png") },
  { image: require("./../../images/client-logo/gorman-rupp.png") },
  { image: require("./../../images/client-logo/goulds.png") },
  { image: require("./../../images/client-logo/grundfos.png") },
  { image: require("./../../images/client-logo/gusher.png") },
  { image: require("./../../images/client-logo/hays.png") },
  { image: require("./../../images/client-logo/helwig.png") },
  { image: require("./../../images/client-logo/honeywell.png") },
  { image: require("./../../images/client-logo/kobold.png") },
  { image: require("./../../images/client-logo/koflo.png") },
  { image: require("./../../images/client-logo/little-giant.png") },
  { image: require("./../../images/client-logo/lovejoy.png") },
  { image: require("./../../images/client-logo/midwest-mixing.png") },
  { image: require("./../../images/client-logo/neovalves.png") },
  { image: require("./../../images/client-logo/primary-fluid-systems.png") },
  { image: require("./../../images/client-logo/prominent.png") },
  { image: require("./../../images/client-logo/pulsafeeder.png") },
  { image: require("./../../images/client-logo/sandpiper.png") },
  { image: require("./../../images/client-logo/scot.png") },
  { image: require("./../../images/client-logo/taco.png") },
  { image: require("./../../images/client-logo/viking.png") },
  { image: require("./../../images/client-logo/warrender.png") },
  { image: require("./../../images/client-logo/watts.png") },
  { image: require("./../../images/client-logo/winters.png") },
];

class ClientsLogo1 extends React.Component {
  render() {
    const options = {
      loop: true,
      nav: false,
      dots: false,
      margin: 30,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplaySpeed: 1000,
      autoplayHoverPause: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 2,
        },
        480: {
          items: 3,
        },
        767: {
          items: 4,
        },
        1000: {
          items: 4,
        },
      },
    };
    return (
      <>
        <div className="section-full bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="section-content">
                <div className="section-content p-tb30 owl-btn-vertical-center">
                  <OwlCarousel
                    className="owl-carousel home-client-carousel-2"
                    {...options}
                  >
                    {logos.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="ow-client-logo">
                          <div className="client-logo client-logo-media">
                            <NavLink to={"#"}>
                              <img src={item.image} alt="" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ClientsLogo1;
