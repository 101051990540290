import React from "react";

export default function MixerContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Midwest Mixers</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Midwest Mixing, Inc. are manufacturers of a complete line of Mixers
          since 1946. Stirrers, Portable Mixers, Vertical and Side Entering
          Mixers. Electric motors are TEFC or explosion proof. Inverters are
          available for variable speed. Air motors come with a needle valve for
          speed control.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/midwest/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Midwest Mixing, Inc. has many standard models but we can design new
            models for specific situations. Midwest Mixing, Inc. also has Paint
            Shakers in air and electric models to five gallon capacity.
          </p>
        </div>
      </div>
      <div>
        <h2>Mixer Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Air Driven Stirrers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>MODEL 3002</td>
                    <td>
                      Stirrer, air driven, 1/6 horse power, 0 to 6000 rpm, with
                      needle valve speed control and tubing connector, silencer,
                      corrosion resistant chuck. 12 inch shaft and 2 inch
                      diameter propeller are of stainless steel. Motor diameter,
                      2 1/2". Weight, 3 lbs. Maximum free air consumption,
                      7.6cfm at 80 lbs. input pressure.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 4000</td>
                    <td>
                      Stirrer, air driven, 1/2 HP,0 to 2000 rpm, with speed
                      control valve, tubing connector, silencer, 1/2 inch
                      capacity corrosion resistant collet chuck; stainless steel
                      shaft, 1/2 x 15"; stainless steel propeller, 3 inch
                      diameter. Motor diameter, 4". Wt. 8 lbs. Maximum free air
                      consumption, at 80 lbs. input pressure, 13.5 cfm.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 4100</td>
                    <td>
                      Stirrer, as above, but 1 HP, 3" propellers. Wt. 10 lbs. 25
                      cfm.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 3050</td>
                    <td>
                      Stirrer, air driven, geared for viscous materials, 1/6 HP,
                      0 to 600 rpm, with needle valve speed control, tubing
                      connector, silencer, 3/8 inch capacity three jaw chuck.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 3</td>
                    <td>
                      Geared air-driven stirrer for viscous or colloidal
                      materials, heavily loaded liquids, or where slow speed is
                      desirable. 1/6 hp air motor 0 to 600 rpm. 3/8" capacity 3
                      jaw chuck. Same motor as Model 1, but geared down 1 to 5.
                      Weight 6 lbs.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 1</td>
                    <td>
                      1/6 HP 0-6000 RPM. Similar to Model 3002, but equipped
                      with a mounting clamp.
                    </td>
                  </tr>
                  <tr>
                    <td>MODEL 2</td>
                    <td>
                      1/2 HP - 2000 RPM. SImilar to Model 4000, except equipped
                      with shaft held in sleeve by set screws.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Bung Mixers for Closed Drums
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Featuring Agitator Drum Mixers models 15A and 35A and Bung
                Mixers models 33AD, 33D, 12D and 13AD.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Direct Drive Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                MIXM Mixers are well balanced. Steels selected for shafts assure
                highest straightness factor and concentricity. Shaft diameter
                and length is chosen to assure optimum performance within
                critical speed limits. The center of gravity is located above
                clamp cup for ease in changing position of mixer or changing
                angle of shaft.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Geared Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                MIXM Portable Mixers are truly portable. They weigh as little as
                possible without compromising quality. Design throughout is to
                assure ease of handling.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Paint Shakers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                - Positive action clamp will not loosen. <br />
                - Ball bearing throughout, prelubricated at factory, doubly
                sealed - no maintenance. <br />
                - Eccentric drive mechanism proven by 22 years in the field.{" "}
                <br />- Explosion proof motor and switch, or 0-30 minute timer
                optional.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Stepless Variable Speed Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                - Operate from 60 cycle AC Source <br />
                - Direct drive or Geared drip proof, totally enclosed, or
                explosion proof <br />
                - Miniaturized, Solid State, Electronic Control <br />
                - Wide Speed Range <br />- Full Rated Torque at All Speeds
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Midwest Mixing Inc., builds Vertical Mixers in various styles
                and designs: direct drive, geared, electrical or air driven (at
                speeds suitable) for the impellers used, the tank dimensions,
                the mixing objectives, and the fluids involved). Mountings can
                be furnished for use on tanks made of steel, fiberglass, wood or
                other materials, open or closed, with or without vacuum or
                pressure, jacketed or non-jacketed, with or without internal
                heating elements.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
