import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";

import logo from "../../images/BPINC-WEB.svg";

function Header() {
  const [state, setState] = useState({ logo });

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logoPath) => {
      setState({ logo: logoPath });
    };

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [state]);

  return (
    <>
      <header className="site-header header-style-4 mobile-sider-drawer-menu">
        <div className="top-bar">
          <div className="container">
            <div className="d-flex justify-content-between">
              <div className="wt-topbar-left d-flex flex-wrap align-content-start">
                <ul className="wt-topbar-info e-p-bx text-white">
                  <li>
                    <span> Monday - Friday</span>
                    <span>8AM - 5PM</span>
                  </li>
                </ul>
              </div>
              <div className="wt-topbar-right d-flex flex-wrap align-content-center">
                <ul className="wt-topbar-info-2 e-p-bx text-white">
                  <li>
                    <i className="fa fa-phone" />
                    +1 (888) 339-2060
                  </li>
                  <li>
                    <i className="fa fa-phone" />
                    +1 (905) 631-7672
                  </li>
                  <li>
                    <i className="fa fa-envelope" />
                    bpump@burlingtonpump.com
                  </li>
                </ul>
                <ul className="social-icons">
                  <li>
                    <a
                      className="fa fa-linkedin"
                      target="_blank"
                      href="https://www.linkedin.com/company/burlington-pump-inc"
                    />
                  </li>
                  <li>
                    <a
                      className="fa fa-facebook"
                      target="_blank"
                      href="https://m.facebook.com/people/Burlington-Pump-Inc/100066951787067/"
                    />
                  </li>
                  <li>
                    <a
                      className="fa fa-instagram"
                      target="_blank"
                      href="https://www.instagram.com/burlingtonpumpinc"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={"/"}>
                    <img src={state.logo} alt="Burlington Pump" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target="#navbar"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              <div className="extra-nav header-2-nav">
                <div className="extra-cell">
                  <div className="header-nav-request">
                    <NavLink to="/contact/quote">
                      Request a Quote <i className="fa fa-angle-right" />
                    </NavLink>
                  </div>
                </div>
              </div>
              {/* MAIN Nav */}
              <Navigation />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
