import React from "react";
import Footer from "../../Parts/Footer";
import Header from "../../Parts/Header";
import ContactSegment from "../../Segments/Contact";
import Banner from "../../Segments/Banner";

var bnrimg = require("../../../images/BP-1920x960.png");

export default function Contact() {
  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Contact Us" pagename="Contact Us" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}
        <ContactSegment />
      </div>
      <Footer />
    </>
  );
}
