import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">ARO Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Ingersoll Rand / ARO Diaphragm Pumps provide stall-free, ice-free
          operation. Their patented “Unstallable” unbalanced air valve design
          avoids stalling issues associated with other pumps.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/aro/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            The “Quick-Dump” exhaust valves divert cold exhaust air from
            ice-prone components, preventing freezing. Backed by a five-year
            warranty, we have the most reliable pump line on the market today.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              ARO is a leading global manufacturer of fluid handling products,
              expertly engineered to deliver performance and reliability
            </p>
            <div className="p-t15 text-uppercase">
              <strong>ARO</strong>
              <span>arozone.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Diaphragm Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                ARO Diaphragm Pumps are a favorite of process professionals
                everywhere. Ingersoll Rand/ARO Diaphragm Pumps provide
                stall-free, ice-free operation in 3/8" thru 3" fluid ports.
                Backed by a five-year warranty, we have the most reliable pump
                line on the market today.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Electronic Interface Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                ARO Electronic Interface Pumps assures consistent flow rates,
                easy maintenance, and pin-point control for a myriad of fluids
                and applications. Add reliable leak detection, an end-of-stroke
                sensor and cycle sensing, and you have a custom electronic
                interface pump right off the shelf.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Piston Pumps & Packages
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                ARO Piston Pumps & Packages are typically used in extrusion and
                finishing applications moving high viscosity fluids including
                caulking, heavy lubricants, mastics, sealers and inks. Piston
                Pumps are available as either a basic pump or an assembled
                package.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Lubrication Equipment
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                ARO Lubrication Equipment delivers unmatched dependability and
                productivity with a full range of lubrication products built to
                a global quality standard to keep all types of fluids moving
                efficiently.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pneumatic Valves & Cylinders
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                ARO Pneumatic Valves & Cylinders...whether it's high flow or
                compact design, count on ARO Valves for your application. ARO
                Cylinders are available in composite, round line repairable and
                disposable, and NFPA square interchangeable.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
