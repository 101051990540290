import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HelwigContent from "./HelwigPumpContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/suppliers/helwig/pumps" component={HelwigContent} />
          <Route path="/suppliers/helwig">
            <Redirect to="/suppliers/helwig/pumps" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
