import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Crown Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Designed for wastewater and construction dewatering, Crown pumps save
          users money through efficient operation, low capital cost and ease of
          serviceability. Superior performance to 4,400 gpm (1000 m3/h) and
          heads to 184 ft (56m) while handling spherical solids up to 3 in
          (80mm). Available in close-coupled and frame-mounted direct or v-belt
          drive configurations.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/crown/BP-800x500.png")}
            alt=""
          />
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Self-Priming Solids Handling Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Crown Pumps offer a broad range of engineered self-priming
                solids handling centrifugal pumps for a variety of light to
                heavy-duty wastewater applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
