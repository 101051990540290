import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Parts/Footer";
import Header from "../../Parts/Header";
import Banner from "../../Segments/Banner";
import ProductDetails from "../../Segments/ProductDetails";

var bnrimg = require("../../../images/BP-1920x960.png");

const products = {
  pumps: "Industrial Pumps",
  "boiler-controls": "Boiler & Level Controls",
  couplings: "Couplings",
  "flow-meters": "Flow Meters",
  mixers: "Industrial Mixers",
  "pump-accessories": "Metering Pump Accessories",
  "pressure-instruments": "Pressure Instruments",
  strainers: "Strainers",
  valves: "Valves",
};

export default function Details() {
  let { type } = useParams();

  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner
          title={products[type]}
          pagename="Products"
          pagename2={products[type]}
          bgimage={bnrimg}
        />
        {/* INNER PAGE BANNER END */}
        <ProductDetails type={type} />
      </div>
      <Footer />
    </>
  );
}
