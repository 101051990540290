import React from "react";
import Header from "../../Parts/Header";
import Footer from "../../Parts/Footer";
import Banner from "../../Segments/Banner";
import ProductSegment from "../../Segments/Products";

var bnrimg = require("../../../images/BP-1920x960.png");

export default function Products() {
  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Products" pagename="Products" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}
        <ProductSegment />
      </div>
      <Footer />
    </>
  );
}
