import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CouplingContent from "./LovejoyCouplingContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/couplings/lovejoy"
            exact
            component={CouplingContent}
          />
          <Route path="/products/couplings">
            <Redirect to="/products/couplings/lovejoy" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
