import React from "react";

var bnr1 = require("./../../images/background/bg-map.png");

class Team2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 bg-white team-bg-section-outer bg-no-repeat bg-center"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START*/}
              <div className="section-head center wt-small-separator-outer text-center">
                <div className="wt-small-separator site-text-primary">
                  <div className="sep-leaf-left" />
                  <div>Our Experts</div>
                  <div className="sep-leaf-right" />
                </div>
                <h2>
                  We are dedicated to providing you the best products and
                  service
                </h2>
              </div>
              {/* TITLE END*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Team2;
