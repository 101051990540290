import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Gusher Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Gusher Pumps, manufacturer of Rumaco®, Ruthman, and Gusher® pumps,
          represents one of the largest selection of centrifugal pumps available
          from a single manufacturer. Models include close-coupled pumps, end
          suction pumps, immersed pumps, self-priming pumps, vortex pumps,
          sludge pumps, multi-stage pumps, full cantilever pumps. Many in
          vertical or horizontal styles. Ideally suited for small and large flow
          rates, and our multi-stage pump is capable of heads in excess of 800
          ft. We also offer a range of diaphragm pumps, and turbine pumps.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/gusher/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Numerous models and sizes permit selection of the most hydraulically
            suitable pump for many capacity/head ranges without having to up or
            down size operating characteristics. Gusher Pumps manufactures pumps
            for a wide range of applications including DIN specifications, ANSI
            specification, machine tool and centralized coolant systems,
            automatic paint systems, industrial spray washers, filtration
            systems, phosphate and bonderizing lines just to name a few.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Our range of industrial pumps represents one of the largest
              selection of centrifugal pumps available from a single
              manufacturer.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Gusher</strong>
              <span>gusher.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Applications</h2>
        <p>
          Gusher Pumps’ custom-manufactured pumps are suited for a wide range of
          applications including:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>DIN specification</li>
              <li>ANSI specification</li>
              <li>Machine tool and centralized coolant systems</li>
              <li>Automatic paint systems</li>
              <li>Industrial spray washers</li>
              <li>Filtration systems</li>
              <li>Phosphate and bonderizing lines</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/gusher/gusher-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  7071 Series Horizontal End Suction Centrifugal Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                The 7071 Series is a horizontal, open impeller centrifugal pump
                that meets the specifications of ANSI B-73.1. The series
                consists of 3 Power Frames and 33 pump sizes.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  7550 Series Vertical End Suction Centrifugal Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Gusher 7550 Series has been designed to give heavy duty
                pumping service in a wide range of industrial applications
                including spray booths, industrial spray washers, paint, and
                coolant systems. Sizes range from 1" to 8" discharge with heads
                up to 275 feet @ 1750 RPM and capacities up to 4000 GPM.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  7600 Series Vertical Top Pull Out Centrifugal End Suction Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                This Series has been designed to minimize maintenance. If
                maintenance is required, the top pull out design allows the
                rotating element to be removed without disturbing the mounting
                plate or piping. Sizes range from 1" to 8" discharge with heads
                up to 275 feet @ 1750 RPM and capacities to 4000 GPM.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  7600 Series Horizontal Back Pull Out Centrifugal End Suction
                  Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The back pull out design of the Gusher 7600H Series saves
                downtime and maintenance costs by providing easy removal of
                rotating assembly for fast repairs. These pumps are available in
                coupled, coupled base mounted, and close coupled configurations.
                Sizes range from 1" to 8" discharge with heads up to 275 feet @
                1750 RPM and capacities up to 2800 GPM.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  7800 Series Vertical and Horizontal Centrifugal End Suction
                  Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The 7800 Series includes our jet pump, self priming pumps,
                vertical and horizontal close coupled pumps, direct drive pumps
                and some models with a range from 0 to 800 GPM @ 3450 RPM and
                head up to 700 feet.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  8000 Series Horizontal Centrifugal Self-priming Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The 8000 Series is designed for a wide range of uses from
                agricultural to industrial, including filtration, irrigation,
                dewatering, transfer, industrial sump, shallow sump washers, and
                tank car unloading. Priming and air separation are accomplished
                within the casing, there are no external valves, air separator,
                or priming chamber required. Sizes range from 1 1/4" to 6"
                discharge with heads up to 130 feet @ 1750 RPM and capacities to
                1200 GPM.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  SHC
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                15 different sizes offering up to 440 foot head and 3000 GPM
                capable of handling solids up to 3 inches.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vortex Non-Clog Series Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                8 sizes offering up to 400 foot head and 2800 GPM capable of
                handling solids up to 6 inches.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Air Operated Diaphragm Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Want to safely and cost effectively transfer all of your process
                and waste fluids? We have what you need.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  High Pressure Multi-Stage Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Gushers Multi-stage pumps offer pressures of up to 400 PSI. Some
                models also offer dual discharges, one for low volume and high
                pressure, and another for low pressure and high volume.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Molten Metal Vertical Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Gushers Molten Metal pumps handle lead, babbitt, solder, tin,
                zinc, spelter, and other molten metals at temperatures up to
                1200°F. These pumps are standard with stainless steel shaft,
                pump parts made of cast iron, and carbon steel screws.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Industrial Coolant Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Gusher offers replacement machine tool coolant pumps for Fuji,
                Mitsubishi, Toshiba, Hitachi, and Yasukawa. Contact our Sales
                department for your specific application.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThirteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Electric Immersion Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThirteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                A breadth of applications including machine tools, chemical
                equipment and refrigeration systems. Capacities up to 32 GPM and
                heads up to 100 feet.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFourteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Turbine Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFourteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Flow rates exceeding 15,000 GPM. Ideal for central coolant
                systems, cooling towers, paint, waste treatments, and many more
                high-pressure applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFifteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Stainless Steel Vertical And Flanged Immersion Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFifteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                When you need a pump you need it fast. We have a large amount of
                these pumps in stock and ready to ship.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSixteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  SC-CC General Service Industrial Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSixteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Capacities to 450 GPM and heads to 160 Feet. Applications
                include water filtration, washers, wastewater and many more.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeventeen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  CL-CC General Service Industrial Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeventeen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Capacities to 1600 GPM and heads to 700 Feet. Applications
                include water purification, parts washers, paint systems, and
                many more.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEighteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Screw Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEighteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                For cooling lubricants. These pumps ensure performance with
                special case coatings. Flow rates up to 160 GPM.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
