import React, { useState, useRef } from "react";
import Footer from "../../Parts/Footer";
import Header from "../../Parts/Header";
import Banner from "../../Segments/Banner";
import bnr from "../../../images/BP-1900x1060.png";
import bnr2 from "../../../images/background/bg-map2.png";
import emailjs from "@emailjs/browser";

var bnrimg = require("../../../images/BP-1920x960.png");

export default function Quote() {
  const [success, setSuccess] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("bpump", "quote_request", form.current, "o5QeFyxFsioMqaePy")
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(result.text);
        },
        (error) => {
          console.log(error.text);
          setSuccess(error.text);
        }
      );
  };

  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Contact Us" pagename="Contact Us" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}

        <div
          className="bg-cover bg-no-repeat"
          style={{
            backgroundImage: "url(" + bnr + ")",
            right: "0%",
            paddingBottom: "60px",
          }}
        >
          <div className="contact-nav">
            <div className="contact-nav-form">
              <div
                className="contact-nav-info bg-white bg-center bg-no-repeat"
                style={{ backgroundImage: "url(" + bnr2 + ")" }}
              >
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="contact-nav-media-section">
                      <div className="contact-nav-media">
                        <img
                          src={require("../../../images/BP-348x710.png")}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 p-a30">
                    {success === "" ? (
                      <form
                        className="cons-contact-form"
                        method="post"
                        action="#"
                        ref={form}
                        onSubmit={sendEmail}
                      >
                        <div className="m-b30">
                          {/* TITLE START */}
                          <h2 className="m-b30">Request Quote</h2>
                          {/* TITLE END */}
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <div className="form-group">
                                <input
                                  name="user_name"
                                  type="text"
                                  required
                                  className="form-control"
                                  placeholder="Name"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="form-group">
                                <input
                                  name="user_email"
                                  type="text"
                                  className="form-control"
                                  required
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="form-group">
                                <input
                                  name="user_phone"
                                  type="text"
                                  className="form-control"
                                  required
                                  placeholder="Phone"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <div className="form-group">
                                <input
                                  name="subject"
                                  type="text"
                                  className="form-control"
                                  required
                                  placeholder="Subject"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <textarea
                                  name="message"
                                  className="form-control"
                                  rows={4}
                                  placeholder="Message"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <input
                                type="submit"
                                className="site-button site-btn-effect"
                                value="Submit Now"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : success === "OK" ? (
                      <div className="cons-contact-form">
                        <h4>Thank you! We will get back to you shortly</h4>
                      </div>
                    ) : (
                      <div className="cons-contact-form">
                        <h4>Something went wrong, please try again shortly.</h4>
                      </div>
                    )}
                    <div className="contact-nav-inner text-black">
                      <div className="row">
                        <div className="col-lg-4 col-md-12">
                          <div className="wt-icon-box-wraper left icon-shake-outer">
                            <div className="icon-content">
                              <h4 className="m-t0">Address</h4>
                              <p className="sm-font">
                                3325 North Service Rd. Unit 106, Burlington,
                                Ontario, Canada, L7N 3G2
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="wt-icon-box-wraper left icon-shake-outer">
                            <div className="icon-content">
                              <h4 className="m-t0">Phone</h4>
                              <p className="sm-font">
                                +1 (888) 339-2060 [TOLL FREE]
                              </p>
                              <p className="sm-font">+1 (905) 631 7672</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <div className="wt-icon-box-wraper left icon-shake-outer">
                            <div className="icon-content">
                              <h4 className="m-t0">Email</h4>
                              <p className="sm-font">
                                bpump@burlingtonpump.com
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
