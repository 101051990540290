import React from "react";
import { NavLink } from "react-router-dom";

const products = [
  {
    title: "High-Quality Parts",
    image: require("./../../images/pipe-valve-duotone.svg").default,
    description:
      "There’s no better place to find the quality, name-brand components you need for your projects.",
  },
  {
    title: "Exceptional Service",
    image: require("./../../images/stars-duotone.svg").default,
    description:
      "With so many years of experience, we build relationships that last. You can count on us to help you every step of the way.",
  },
  {
    title: "Fast Shipping",
    image: require("./../../images/truck-fast-duotone.svg").default,
    description:
      "We're quick to ship out our parts and always make sure they'll get to you exactly when you need them.",
  },
  {
    title: "Engineered Solutions",
    image: require("./../../images/helmet-safety-duotone.svg").default,
    description:
      "We don't just sell you pumps, we will figure out the most optimal solutions for your project.",
  },
  {
    title: "Proudly Canadian",
    image: require("./../../images/canadian-maple-leaf.svg").default,
    description:
      "We're based in Burlington, ON, but provide products to customers across Canada, the US, and worldwide.",
  },
  {
    title: "Awesome Team",
    image: require("./../../images/people-group-duotone.svg").default,
    description:
      "We pride ourselves on our work ethic and problem solving, giving it our all on every project.",
  },
];

var bnr1 = require("./../../images/background/bg-5.png");

class Products2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  p-t80 p-b50 bg-white  bg-bottom-right bg-no-repeat"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            {/* TITLE START*/}
            <div className="section-head center wt-small-separator-outer">
              <div className="wt-small-separator site-text-primary">
                <div className="sep-leaf-left" />
                <div>Solutions provided to</div>
                <div className="sep-leaf-right" />
              </div>
              <h2>Many clients across Canada, The US, and Worldwide</h2>
            </div>
            {/* TITLE END*/}
            <div className="row d-flex justify-content-center">
              {products.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 animate_line">
                  <div className="service-border-box3 shadow text-center">
                    <div className="wt-icon-box-wraper bg-white p-tb50 p-lr20">
                      <div className="icon-lg inline-icon	 m-b50">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="icon-content">
                        <h3 className="wt-tilte m-b20">{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Products2;
