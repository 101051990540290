import React from "react";

export default function ValveContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Hays Fluid Control Valves</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Hays Fluid Controls manufactures automatic flow control valves in
          imperial and metric sizes, which operate over a wide range of
          differential pressures. Hays brings peace of mind to system success by
          providing trusted, military-grade valves and designs supported by
          superior, personalized service to help form long-lasting connections.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/hays/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Founded in 1865, Hays is committed to exceeding customer
            expectations, building high quality products, and providing a
            friendly, safe, and fulfilling environment for employees.
          </p>
        </div>
      </div>
      <div>
        <h2>Valve Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  WSHP and Fan Coil Connection Kits
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                HoseKits are a convenient method of combining the advantages of
                flow control and a flexible connection. Our kits are supplied
                with a Mesurflo™Automatic Balancing valve, a non-ported ball
                valve, two stainless steel braided hoses, and a ball valve with
                P/T ports. All kits are 100% leak tested before shipping!
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Automatic Balancing Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The "Gold Standard" For Controlling Water Flow In HVAC Systems
                Automatic Balancing Valves - Hays' patented Mesurflo® automatic
                flow control valves have been called the "Gold Standard" because
                their unique features and benefits make them the ideal solution
                for controlling flow in waterbased cooling and heating systems.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Manual Balancing Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Manual Balancing Valves fom hays Fluid Controls featuring,
                Venturi Type Manual Balancing Valve, Hays Mesurmeter Manual
                Balancing Valve, Hays Mesurmeter for Flow Verification, Hays
                Y-Ball Venturi Type Manual Balancing Valve, Hays Y-Ball Venturi
                Type Manual Balancing Valve and Series CBVF Combination
                Butterfly/Venturi with Flange.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Valve Components
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Valve Components from Hays Fluid Controls featuring stainless
                steel braided hose, condensate hose, ball valves, Y-Ball
                combination strainer, cast iron wye strainer, combination
                butterfly valve & wye strainer, steel hook-up accessories, BFA
                butterfly valve, automatic temperature control valve and valve
                actuators.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Marine Products
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Automatic flow control/balancing valve for shipboard chilled
                water air conditioning systems. Electronic cooling water systems
                (ECWS) and sea water cooling water systems
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Solenoid Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Solenoid Valves from Hays Fluid Controls consist of the Series
                2110 Electroflo Solenoid Valve, Series 2192 Electro-Steam
                Piston/Pilot Type Solenoid Valve, Series 3108 Duo-Electroflo
                Solenoid Valve, and Mesurflo® Automatic Flow Control Valve
                Series 2305 and 2307.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pressure Independent Control Valves (PICV)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The The Mesurflo® Pressure Independent Control Valves (PICV)
                from Hays Fluid Controls prevents the operating system from
                experiencing excessive flow. In conditions where higher than
                normal pressures occur, Mesurflo® Pressure Independent Control
                Valves (PICV) lowers total system flow.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
