import React from "react";

const projects = [
  {
    image: require("./../../images/markets/marine.png"),
  },
  {
    image: require("./../../images/markets/food-beverage.png"),
  },
  {
    image: require("./../../images/markets/chemical-processing.png"),
  },
  {
    image: require("./../../images/markets/pulp-paper.png"),
  },
  {
    image: require("./../../images/markets/hvac.png"),
  },
  {
    image: require("./../../images/markets/power-generation.png"),
  },
  {
    image: require("./../../images/markets/waste-water.png"),
  },
  {
    image: require("./../../images/markets/automotive.png"),
  },
  {
    image: require("./../../images/markets/steel.png"),
  },
];

class Products extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white">
          <div className="container">
            {/* TITLE START*/}
            <div className="section-head center wt-small-separator-outer">
              <h2>Market Sectors</h2>
              <p style={{ width: "400px", margin: "auto" }}>
                Only from a company focused on quality & innovation with
                specific attention to these 9 key markets.
              </p>
            </div>
            {/* TITLE END*/}
            {/* GALLERY CONTENT START */}
            <div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}
                >
                  <div className="">
                    <img src={item.image} alt="" />
                    {/* <div className="wt-info">
                      <h3 className="wt-tilte m-b10 m-t0">{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                    <a href={item.image} className="mfp-link">
                      <i className="fa fa-search-plus" />
                    </a> */}
                  </div>
                </div>
              ))}
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
      </>
    );
  }
}

export default Products;
