import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import WintersContent from "./WintersPressureInstrumentContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/suppliers/winters/pressure-instruments"
            component={WintersContent}
          />
          <Route path="/suppliers/winters">
            <Redirect to="/suppliers/winters/pressure-instruments" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
