import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import BurkertContent from "./BurkertFlowMeterContent";
import BurkertValveContent from "./BurkertValveContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/suppliers/burkert/flow-meters"
            component={BurkertContent}
          />
          <Route
            path="/suppliers/burkert/valves"
            component={BurkertValveContent}
          />
          <Route path="/suppliers/burkert">
            <Redirect to="/suppliers/burkert/flow-meters" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
