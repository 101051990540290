import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    icon: require("../../images/service-icons/Icon1.png"),
    title1: "Industrial",
    title2: "Pumps",
  },
  {
    count: "02",
    icon: require("../../images/service-icons/Icon2.png"),
    title1: "Boiler &",
    title2: "Level Controls",
  },
  {
    count: "03",
    icon: require("../../images/service-icons/Icon3.png"),
    title1: "Calibration",
    title2: "Cylinders",
  },
  {
    count: "04",
    icon: require("../../images/service-icons/Icon4.png"),
    title1: "Corporation",
    title2: "Stops",
  },
  {
    count: "05",
    icon: require("../../images/service-icons/Icon12.png"),
    title1: "Heat",
    title2: "Exchangers",
  },
  {
    count: "06",
    icon: require("../../images/service-icons/Icon5.png"),
    title1: "Industrial",
    title2: "Couplings",
  },
  {
    count: "07",
    icon: require("../../images/service-icons/Icon6.png"),
    title1: "Flow",
    title2: "Meters",
  },
  {
    count: "08",
    icon: require("../../images/service-icons/Icon7.png"),
    title1: "Injection",
    title2: "Quills",
  },
  {
    count: "09",
    icon: require("../../images/service-icons/Icon8.png"),
    title1: "Industrial",
    title2: "Mixers",
  },
  {
    count: "10",
    icon: require("../../images/service-icons/Icon9.png"),
    title1: "Pulsation",
    title2: "Dampeners",
  },
  {
    count: "11",
    icon: require("../../images/service-icons/Icon10.png"),
    title1: "Pressure",
    title2: "Instrumentation",
  },
  {
    count: "12",
    icon: require("../../images/service-icons/Icon11.png"),
    title1: "Strainers &",
    title2: "Valves",
  },
];

var bnr1 = require("./../../images/background/bg-7.jpg");

class Services13 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START*/}
              <div className="section-head center wt-small-separator-outer text-center">
                <div className="wt-small-separator site-text-primary">
                  <div className="sep-leaf-left" />
                  <div>The Best Industry Products</div>
                  <div className="sep-leaf-right" />
                </div>
                <h2>
                  We’ve expanded our selection to include all the essentials
                  (and beyond)!
                </h2>
              </div>
              {/* TITLE END*/}
              <div className="row d-flex justify-content-center">
                {services.map((item, index) => (
                  <div
                    key={index}
                    className="col-lg-4 col-md-6 col-sm-12 m-b50"
                  >
                    <div className="service-border-box">
                      <div className="wt-box service-box-1 bg-white">
                        <div className="service-box-title title-style-2 site-text-secondry">
                          <span className="s-title-one">{item.title1}</span>
                          <span className="s-title-two">{item.title2}</span>
                        </div>
                        <div className="wt-icon-box-wraper">
                          <div className="wt-icon-box-md site-bg-primary">
                            <span className="icon-cell text-white">
                              <img
                                style={{ width: "48px", height: "60px" }}
                                src={item.icon}
                                alt="icon"
                              />
                            </span>
                          </div>
                          <div className="wt-icon-number">
                            <span>{item.count}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services13;
