import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Pacer Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Pacer Pumps provides a variety of pumps for the industrial market,
          from the popular S Series and I Series, to our Magnetic Coupled,
          Vertical, Drum and Hand Operated Pumps. Browse our Industrial Pumps
          pages to learn more about the most popular pumps for the industrial
          market, then contact Pacer Pumps today to learn how Pacer Pumps can
          help you with your specific industrial application.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/pacer/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Pacer Pumps is dedicated to providing quality products and
              services that satisfy our customers' needs and improve their
              productivity and profitability.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Pacer</strong>
              <span>pacerpumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Markets & Applications</h2>
        <p>
          Pacer Pumps is the originator and is recognized as the leading
          manufacturer of high-performance centrifugal pumps molded from tough
          modern thermoplastics. Our brand and service is often imitated but
          never duplicated. Successful adaptation of these products for
          agricultural, aquaculture, marine, farm/home and industrial
          applications has resulted in worldwide market acceptance. From farmers
          to fishermen to industrial mechanics, people turn to Pacer for quality
          pumps.
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Agriculture</li>
              <li>Aquaculture</li>
              <li>Marine</li>
              <li>Farm/Home</li>
              <li>Industrial</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/pacer/pacer-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Drum Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Pacer's DP Series Drum pumps are engineered to safely transfer
                aggressive chemicals from drums, tanks and large storage
                vessels.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  G Series Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer Pumps' 'G' Series Self-Priming Centrifugal pumps - the
                self-priming ability, chemical resistance, light weight and
                basket strainer make this an ideal chemical transfer, waste
                treatment, emergency/backup or filter pump.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Hand Operated Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer's HP Series Hand Dispensing pump is a double-acting piston
                type which will handle a wide range of solutions.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  I Series Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer Pumps' I Series Self-Priming Centrifugal pumps are
                lightweight and provide corrosion resistance and efficient
                performance for a wide variety of water and corrosive liquid
                applications - for chemicals, wastes, acids and other industrial
                liquids.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Magnetic Coupled Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer's Magnetic Coupled, Seal-Less Centrifugal pumps are molded
                from non-metallic corrosion resistant material. They can be
                constructed to accommodate a wide variety of applications such
                as photoprocessing, water & waste treatment, semiconductor,
                industrial chemical processing, high purity DI & RO water, paper
                & pulp chemical processing, fume scrubbing and plating
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  PC Series Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The PC Series pump from Pacer Pumps - for pumping, transferring
                or recirculating of acids, plating solutions, photographic
                chemicals, fume scrubber, waste water, etching and cleaning
                solutions. All major pump parts are precision molded of tough,
                corrosion resistant glass-reinforced polyester, polypropylene,
                Ryton or Noryl thermoplastic.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  S Series Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer Pumps' S Series Self-Priming Centrifugal pumps are
                lightweight, chemically resistant pumps for general service on
                water, salt water, waste water, mild acids and bases.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  T Series Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Pacer Pumps' T Series pumps are self-priming, solids handling
                centrifugal pumps that will pass a solid half the diameter of
                the suction port.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Series 'O' Vertical Pump features a cantilevered sleeved
                stainless steel shaft which eliminates bearings and seals,
                resulting in a pump that can run dry without damage.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
