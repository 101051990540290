import React from "react";
import { Route, Switch } from "react-router-dom";
import AWLakeContent from "./AWLakeFlowMeterContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/suppliers/aw-lake" exact component={AWLakeContent} />
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
