import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">G&L Goulds Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          ITT Goulds Pumps is among the most widely recognized and respected
          brands in the global pump industry, serving customers in the oil and
          gas, mining, power generation, chemical, pulp and paper, and general
          industrial markets.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/gl-goulds/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            As the only manufacturer to make digital monitoring standard on
            every process pump, ITT Goulds Pumps continues to lead the industry
            in both mechanical pump design and the adoption of smart
            technologies.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Goulds has become the world’s largest manufacturer because it has
              refused to grow old, because we have continually looked forward,
              confident that we possessed in men, in manaagement, in materials,
              and in money, resources sufficient to meet the needs and demands
              of each successive generation.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Norman J. Gould</strong>
              <span>Goulds Pumps</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Product Line</h2>
        <p>
          G&L, a part of the Goulds Pump family, is a worldwide leader in the
          manufacture of centrifugal pumps and ancillary components. The line
          consists of the following:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>
                Stainless steel, bronze, and iron end-suction pumps with flows
                to 5,000 GPM and 400' of head for water and mild-chemical duty
                applications.
              </li>
              <li>
                Multi-stage pumps with flows to 350 GPM and heads to 800In
                addition
              </li>
              <li>
                Texas Turbine division of Goulds which manufactures vertical
                turbines for larger flow and head requirements.
              </li>
              <li>
                Aquavar Variable Frequency Drive units (VFD's) for
                variable-torque speed control of centrifugal pumps
              </li>
              <li>Self-priming pumps</li>
              <li>
                The breadth and depth of the G&L product offering means that
                they can fulfill a wide range of applications.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/gl-goulds/goulds-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Multi-Stage End Suction Booster Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Multi-Stage End Suction horizontal and vertical configuration
                high pressure pumps for water boost applications in both surface
                and tank mount environments. Stainless steel or cast iron
                construction with variable suction and discharge orientation.
                Flows to 350 GPM and 800 feet TDH. Designed to handle a wide
                range of filtration, pressure boost, wash system and boiler feed
                applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Self-Priming End Suction Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                G&L Goulds Pumps Self-Priming End Suction Centrifugal Pumps
                Stainless Steel, Cast Iron and Bronze construction in both self
                priming and standard configurations. Close coupled, frame
                mounted and SAE engine drive options available. Designed to
                handle a wide range of water and mild chemical applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Single Stage End Suction Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Stainless Steel, Cast Iron and Bronze construction in both self
                priming and standard configurations. Close coupled, frame
                mounted and SAE engine drive options available. Flows up to
                5,000 GPM and 400 feet TDH. Designed to handle a wide range of
                water and mild chemical applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Single-Stage Double Suction Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Single-Stage Double Suction centrifugal pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  A-C Series 1500 Vertical Single-Stage In-Line Centrifugal Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Series A-C 1500 is an efficient, heavy-duty, close-coupled
                in-line mounted pump available in several sizes from 1-1/2”
                through 8”. They are available in bronze fitted and all cast
                iron construction.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Effluent Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                G&L Goulds Pumps Wastewater and Drainage Effluent Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Grinder Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                G&L Goulds Pumps Wastewater and Drainage Grinder Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sewage Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                G&L Goulds Pumps Wastewater and Drainage Grinder Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sump Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                G&L Goulds Pumps Wastewater and Drainage Grinder Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  G&L Variable Speed Pump Controllers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Variable frequency drive controllers specifically designed for
                pumping systems. May be used to reduce electricity cost by up to
                70% over fixed speed pumps in most applications. Self contained
                pump mounted or panel mounted control systems designed for
                standard pump and motor combinations.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
