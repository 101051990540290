import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Weinman Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          When it comes to general industrial, municipal, commercial and
          building service pumps, Weinman is widely recognized for quality,
          efficiency and economy. Weinman pumps are particularly adept in HVAC
          and water service applications
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/weinman/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Weinman pumping equipment and systems are widely found in boiler
            feed or booster service, chilled or hot water, cooling tower
            condensate and sprinkler, ice storage and liquid circulation.
            General water services include potable water transmission, sump and
            sewage ejection, municipal and industrial water treatment.
          </p>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Condensate Return Units
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Weinman condensate return units are designed for automatic
                return of hot water condensation from radiators, coils, etc. to
                low and high pressure boilers, or for return of water and other
                liquids to the overhead tanks of industrial gravity circulating
                systems.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  End Suction Centrigual Pumps (Frame Mounted /Closed Coupled)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Weinman End Suction (Frame Mounted /Closed Coupled) versatile,
                low cost centrifugal pumps are engineered for use on most phases
                of liquid handling.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Inline Vertical Uni-Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Weinman In-Line Uni-Pumps are specifically designed for heating,
                air conditioning and industrial applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Horizontal And Vertical Dry Pit Sewage Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Weinman horizontal and vertical dry pit sewage pumps are compact
                in design to meet a wide range of service conditions.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Horizontal Split Case Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Weinman horizontal split-case single stage double suction,
                general purpose pump, designed for continuous service handling
                both hot and cold fluids.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Sump Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Weinman General Service Vertical Sump Pumps are designed to
                handle various fluids from drainage water or any liquid waste
                containing small, non-fibrous solids to larger industrial
                processes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
