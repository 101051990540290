import React from "react";

export default function ValveContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Primary Fluid System Valves</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Primary Fluid Systems begin in 1991, as two people building systems
          for local applications. Since that time, we have left system-building
          to the pros, and focused on what we do best: manufacture and sell
          metering pump accessories for a variety of industries. Our innovative
          and patented designs ensure that we provide the best product for your
          specific application. Our company has expanded to nearly 20 people,
          and our products are used in the Americas, Europe, Africa, and Asia.
          We serve customers in diverse industries, including water and
          wastewater treatment, oil and gas, pharmaceutical, and food and
          beverage. With a large inventory available, we can meet our customers'
          needs for specific product configurations in a short time frame.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/primary-fluid/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            With 25 years under our belt, and many of our valves lasting in the
            field for more than 15 years, our products outlast the competition.
            Each product has specific innovations that differentiate it from our
            competitors'. Additionally, we are uniquely able to customize our
            products based on customer specification. We pride ourselves on
            having qualified Technical Sales Representatives who can assist our
            customers in selecting the best product for their application, and
            ensure chemical compatibility. Contact us with any inquiries and we
            will be happy to assist.
          </p>
        </div>
      </div>
      <div>
        <h2>Valve Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  ACCU-VENT Degassing Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                ACCU-VENT Automatic Degassing Valves are designed to
                automatically vent gases and vapors that are commonly released
                from chemicals such as Sodium Hypochlorite or Hydrogen Peroxide
                or Sulfuric Acid.
                <br />
                <br />
                <b>
                  <i>Standard Features</i>
                </b>
                <br />
                - CPVC (Corzan) and Viton corrosion resistant wetted materials
                of construction
                <br />
                - Specially designed float material that automatically vents
                built up gases on system start up and under working pressure
                <br />
                - Can be used on feed side or discharge side of pump (or both)
                <br />
                - Standard 1/2" NPT threaded connections or optional 3/4" NPT
                <br />- Available in other materials of construction for various
                gaseous liquid chemicals
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  TOP VALVE Back Pressure Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Back Pressure Valves and Pressure Relief Valves named Top Valve.
                A new line of diaphragm style, has been introduced by Primary
                Fluid Systems Inc. New features include a completely adjustable
                pressure range of 0-150 PSIG and a built-in air release to
                reduce priming difficulties with metering pumps. Top Valve Back
                Pressure Valves and Pressure Relief Valves are available in
                seven sizes, 1/4" through 2" NPT and eight (8) materials of
                wetted construction. The seat design of this new line of valves
                greatly extends the normal life expectancy of the diaphragm.
                <br />
                <br />
                <b>
                  <i>Standard Features</i>
                </b>
                <br />
                - PVC, CPVC, PVDF, Teflon, polypropylene, stainless steel, Alloy
                20, Hastelloy C<br />
                - Teflon laminated to EPDM backer diaphragm
                <br />
                - Turn down handle limits pressure to 150 psig
                <br />
                - Color coded handles indicate size at a glance
                <br />
                - Rectangular shape with mounting ports
                <br />
                - Built-in matching material gauge port plug (easily removed for
                gauge option)
                <br />
                - Spring design provides consistent pressure adjustments through
                the range (0-150 PSIG)
                <br />
                - Long life diaphragm
                <br />
                - Range of 0 - 150 PSIG
                <br />
                - Built-in optional, air release & gauge port
                <br />
                - Available in 2 port off-line & 3 port in-line configurations
                <br />
                - Available in: PVC, CPVC, PVDF, Teflon, Polypropylene,
                Stainless Steel, Alloy 20, Hastelloy C<br />
                - 7 sizes (1/4" - 2" NPT)
                <br />- Color coded handles indicate size at a glance
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
