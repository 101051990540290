import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Crane Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Crane Pumps & Systems Canada serves the diverse needs of diverse
          markets for fluid handling equipment throughout Canada. All of the
          Crane Pumps & Systems brands - Barnes Pumps, Barnes Pressure Systems,
          Burks Pumps, Crown Pumps, Deming Pumps, Prosser Pumps, and Weinman
          Pumps - are sold and serviced through Burlington Pumps.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/crane/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Crane Pumps & Systems launches the Barnes Transfer Pump to
            complement our extensive Residential Building offering. Barnes
            Transfer pumps are designed to handle a wide variety of
            applications, such as charging polyglycol lines for radiant heating,
            pressure boosting, draining pool covers and dewatering flooded
            basements.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Crane Pumps & Systems creates innovative pump solutions for the
              effective and efficient transport of wastewater.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Crane Vision Statement</strong>
              <span>cranepumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}
