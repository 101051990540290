import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Helwig Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Helwig pumps are ideal for use in chemical feed to boilers, waste
          water treatment, the pulp and paper industry and related applications
          where a controlled volume of chemical treatment is required.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/helwig/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            These heavy duty piston metering pumps are built for minimum
            maintenance from quality materials. Output flow rates are up to 120
            Gallons per hour and output pressure are up to 1000 PSI depending
            upon model. The V-7 piston pump is a compact unit designed for
            lighter metering applications. Burlington Pump sells helwig pump
            series V-10 pumps, V-5 pumps, and V-7 pumps (reciprocating
            piston/plunger, positive displacement chemical metering pumps) A&F
            Piston Pumps and A&F Parts.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              A&F Machine Products Company has been a family-owned and operated
              corporation since 1964.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Helwig</strong>
              <span>helwigpumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  V-10 Piston Metering Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                The A&F Machine V-10 Pump series provides an innovative piston
                metering pump line for heavy duty applications. These pumps
                offer feed rates up to 2880 GPD with outputs to 1000 PSI.
                Available in four base models: the V-10 Simplex pump, V-10
                Duplex pump, V-10 Triplex pump, and V-10 Quad pump. 56C Frame
                Mount 1750 RPM Flange Drive Motor.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  V-10-H Hydraulic Diaphragm Metering Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The A&F Machine V-10-H Pump series provides an innovative
                heavy-duty diaphragm metering pump line for aggressive chemical
                applications. These pumps offer feed rates up to 2880 GPD with
                outputs to 1000 PSI. Available in four base models: the V-10-H
                Simplex pump, V-10-H Duplex pump, V-10-H Triplex pump, and
                V-10-H Quad pump. 56C Frame Mount 1750RPM Flange Drive Motor.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  V-7 Mini Piston Metering Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The A&F Machine V-7 Pump series mini piston metering pump is the
                smaller, compact, light metering pump equivalent of the V-10 or
                V-5. These piston pumps offer feed rates up to 48 GPD with
                outputs rated at 100 PSI. Driven by a low horsepower gear motor
                for lighter metering applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  V-6 Mini Diaphragm Metering Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The A&F Machine V-6 Pump series mini diaphragm metering pump is
                the smaller, compact, light metering pump equivalent of the
                V-10-H or V-5-H. These diaphragm pumps offer feed rates up to 48
                GPD with outputs rated at 100 PSI. Driven by a low horsepower
                gear motor for lighter metering applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
