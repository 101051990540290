import React from "react";

export default function FlowMeterContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Flux Flow Meters</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          FLUX flow meters constructed on the nutating disc principle (FMC),
          oval rotor (FMO) or the turbine principle (FMT), provide the right
          solution for every application. Depending on model and overall size
          they can be used on e.g. FLUX drum pumps or stationary e.g. in pipe
          lines.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/flux/BP-800x500-1.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Using the digital display unit FLUXTRONIC® for FMC and FMO filling
            and metering processes can be carried out for virtually all liquids
            with maximum precision and the greatest possible safety. In
            automatic mode there is the possibility of transmitting signals for
            control purposes. In this way differing processes can be regulated.
          </p>
        </div>
      </div>
      <div>
        <h2>Flow Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Type FMC 100
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Nutating disc type flowmeter. For portable use with FLUX barrel
                and container pumps or fixed installation into pipework.
                Suitable for liquids up to 2.500 mPas (cP).
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Type FMC 250
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Nutating disc type flowmeter. For fixed installation into
                pipework. Suitable for liquids up to 2.500 mPas (cP).
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Type FMO
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Oval rotor type flowmeter. For fixed installation into pipework
                or portable use with FLUX high viscosity liquid pumps. Suitable
                for flowrates of 0,1 to 350 l/min and viscosities up to 500.000
                mPas (cP).
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Type FMT 50 PP
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The interlocked wheel impeller in the measuring chamber
                guarantees a fast and safe filling process. Suitable for
                neutral, low viscosity, not flammable liquids in conjunction
                with our JUNIORFLUX pump.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
