import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FluxMixerContent from "./FluxMixerContent";
import MidwestMixerContent from "./MidwestMixerContent";
import KofloMixerContent from "./KofloMixerContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/products/mixers/flux" component={FluxMixerContent} />
          <Route
            path="/products/mixers/midwest"
            component={MidwestMixerContent}
          />
          <Route path="/products/mixers/koflo" component={KofloMixerContent} />
          <Route path="/products/mixers">
            <Redirect to="/products/mixers/flux" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
