import React from "react";
import { Route, Switch } from "react-router-dom";
import PumpSidebar from "./PumpSidebar";
import BoilerControlSidebar from "./BoilerControlSidebar";
import CouplingSidebar from "./CouplingSidebar";
import FlowMeterSidebar from "./FlowMeterSidebar";
import MixerSidebar from "./MixerSidebar";
import PumpAccessorySidebar from "./PumpAccessorySidebar";
import PressureInstrumentSidebar from "./PressureInstrumentSidebar";
import StrainerSidebar from "./StrainerSidebar";
import ValveSidebar from "./ValveSidebar";
import PumpDetails from "./PumpDetails";
import BoilerControlDetails from "./BoilerControlDetails";
import CouplingDetails from "./CouplingDetails";
import FlowMeterDetails from "./FlowMeterDetails";
import MixerDetails from "./MixerDetails";
import PumpAccessoryDetails from "./PumpAccessoryDetails";
import PressureInstrumentDetails from "./PressureInstrumentDetails";
import StrainerDetails from "./StrainerDetails";
import ValveDetails from "./ValveDetails";

const sidebars = {
  pumps: <PumpSidebar />,
  "boiler-controls": <BoilerControlSidebar />,
  couplings: <CouplingSidebar />,
  "flow-meters": <FlowMeterSidebar />,
  mixers: <MixerSidebar />,
  "pump-accessories": <PumpAccessorySidebar />,
  "pressure-instruments": <PressureInstrumentSidebar />,
  strainers: <StrainerSidebar />,
  valves: <ValveSidebar />,
};

class ProductDetails extends React.Component {
  render() {
    return (
      <>
        <div className="section-full small-device  p-t80 p-b50 bg-white">
          <div className="section-content">
            <div className="container">
              <div className="row">
                {sidebars[this.props.type]}
                <Switch>
                  <Route path="/products/pumps" component={PumpDetails} />
                  <Route
                    path="/products/boiler-controls"
                    component={BoilerControlDetails}
                  />
                  <Route
                    path="/products/couplings"
                    component={CouplingDetails}
                  />
                  <Route
                    path="/products/flow-meters"
                    component={FlowMeterDetails}
                  />
                  <Route path="/products/mixers" component={MixerDetails} />
                  <Route
                    path="/products/pump-accessories"
                    component={PumpAccessoryDetails}
                  />
                  <Route
                    path="/products/pressure-instruments"
                    component={PressureInstrumentDetails}
                  />
                  <Route
                    path="/products/strainers"
                    component={StrainerDetails}
                  />
                  <Route path="/products/valves" component={ValveDetails} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductDetails;
