import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Armstrong Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Armstrong is a world leader in the development of innovative
          fluid-flow solutions. The company develops products for customers in
          commercial, residential and industrial markets, and serves those
          customers in countries around the world.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/armstrong/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Our product offering includes Circulators, Valves and Fittings,
            Suction Guides, Circuit Balancing valves, Vortex Air separators,
            Horizontal and Vertical pumps, Sump and Sewage pumps, Pressure
            Booster systems, Fire pump packages, Instantaneous Water Heaters,
            Heat Exchangers and Expansion tanks.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              We aim to elevate the industry to a high plane; to sell not merely
              so much cast iron but an engineering service.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Samuel Allan Armstrong</strong>
              <span>Armstrong Fluid Technology</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Design Envelope Technology</h2>
        <p>
          Design Envelope technology enables the greenest, most flexible and
          most cost effective fluid-flow and HVAC systems on the planet –
          resulting in both, lowest installed and lowest operating cost with the
          same equipment. Design Envelope technology is a demand based
          intelligent control solution that:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Models equipment and system behavior.</li>
              <li>Monitors actual system conditions.</li>
              <li>
                Dynamically adjusts equipment operation to match system demand
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/armstrong/BP-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Vertical In-Line Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Armstrong's Vertical In-Line Pumps are a design innovation that
                continues to lead the industry. Armstrong improved the
                efficiency and utility of commercial pumps by designing the
                Vertical In-Line pump in the early 1970s
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  dualArm Vertical In-Line Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong’s new dualArm vertical in-line pumps incorporate two
                Series 4300 or 4380 pumps in a single casing. Series 4302 and
                Series 4382.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Twin Vertical In-Line Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Armstrong Twin Vertical In-Line pumps draws on a proven,
                industry-leading design that makes it the most cost effective
                solution for commercial HVAC systems and the perfect choice for
                your project.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  PiB Series - Pump-in-a-Box
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The PiB Series builds on the quality and unsurpassed performance
                you’ve come to expect from Armstrong commercial pumps.
                Pump-in-a-Box (PiB) models are packaged for warehouse
                distribution or shipment within hours of the order.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical In-Line Pump for HVAC Series 4360
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong’s economical, lower-range vertical inline pump for
                HVAC, domestic water systems, and industrial applications
                requiring a compact pump. Positive sealing assured by proven
                inside-type mechanical seal.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Close-Coupled Vertical In-Line Pump Series 4380
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong’s close-coupled vertical in-line pump features
                economical inside-type, single-spring mechanical seal, serviced
                by removing the rotating assembly while the pump casing remains
                in the line.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Based Mounted Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong offers a comprehensive range of based mounted pumps
                designed for a wide variety of applications. These base mounted
                pumps are single stage, end suction, radially-split centrifugal
                pumping units featuring self-venting casings to prevent air
                binding.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Motor Mounted Horizontal End Suction Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Motor mounted version of the horizontal end suction pump, with
                flanged connections. Radially-split volute with center-line
                discharge. Motor features heavy duty, grease-lubricated ball
                bearings.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Horizontal Split Case Pump Series 4600
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Series 4600 is the state of the art Horizontal Split Case
                pump – based on the "Tilted Parting" concept to minimize
                turbulence at the eye of the impeller by its straight laminar
                approach, thus maximizing efficiency.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Vertical Multi-Stage Pumps Series 4700
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong Series 4700 Vertical Multi-stage pumps offer an
                impressive combination features including high-pressure
                performance, small footprint, stainless steel design,
                high-efficiency and tolerance for temperatures make this an
                ideal solution for a variety of applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Close Coupled Motor Mounted Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Close coupled, motor mounted pump is available in bronze-fitted
                and all-bronze construction. All models provide tapped openings
                for venting, draining, and gauge connections.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Armstrong HE Series Pump Motors
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Armstrong HE Series pump motors are general purpose polyphase
                squirrel cage induction motors meeting all required NEMA and
                IEEE standards.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
