import React from "react";
import { Route, Switch } from "react-router-dom";
import ArmstrongSidebar from "./ArmstrongSidebar";
import AROSidebar from "./AROSidebar";
import AWLakeSidebar from "./AWLakeSidebar";
import BarnesSidebar from "./BarnesSidebar";
import BurkertSidebar from "./BurkertSidebar";
import BurksSidebar from "./BurksSidebar";
import CasterSidebar from "./CasterSidebar";
import CraneSidebar from "./CraneSidebar";
import CrownSidebar from "./CrownSidebar";
import DemingSidebar from "./DemingSidebar";
import FluxSidebar from "./FluxSidebar";
import GouldsSidebar from "./GouldsSidebar";
import GrundfosSidebar from "./GrundfosSidebar";
import GusherSidebar from "./GusherSidebar";
import HaysSidebar from "./HaysSidebar";
import HaywardSidebar from "./HaywardSidebar";
import HelwigSidebar from "./HelwigSidebar";
import KoboldSidebar from "./KoboldSidebar";
import KofloSidebar from "./KofloSidebar";
import LovejoySidebar from "./LovejoySidebar";
import McDonnellMillerSidebar from "./McDonnellMillerSidebar";
import MidwestSidebar from "./MidwestSidebar";
import PacerSidebar from "./PacerSidebar";
import PrimaryFluidSidebar from "./PrimaryFluidSidebar";
import PromagSidebar from "./PromagSidebar";
import ProsserSidebar from "./ProsserSidebar";
import WebsterSidebar from "./WebsterSidebar";
import WeinmanSidebar from "./WeinmanSidebar";
import WintersSidebar from "./WintersSidebar";
import ArmstrongDetails from "./ArmstrongDetails";
import ARODetails from "./ARODetails";
import AWLakeDetails from "./AWLakeDetails";
import BarnesDetails from "./BarnesDetails";
import BurkertDetails from "./BurkertDetails";
import BurksDetails from "./BurksDetails";
import CasterDetails from "./CasterDetails";
import CraneDetails from "./CraneDetails";
import CrownDetails from "./CrownDetails";
import DemingDetails from "./DemingDetails";
import FluxDetails from "./FluxDetails";
import GouldsDetails from "./GouldsDetails";
import GrundfosDetails from "./GrundfosDetails";
import GusherDetails from "./GusherDetails";
import HaysDetails from "./HaysDetails";
import HaywardDetails from "./HaywardDetails";
import HelwigDetails from "./HelwigDetails";
import KoboldDetails from "./KoboldDetails";
import KofloDetails from "./KofloDetails";
import LovejoyDetails from "./LovejoyDetails";
import McDonnellMillerDetails from "./McDonnellMillerDetails";
import MidwestDetails from "./MidwestDetails";
import PacerDetails from "./PacerDetails";
import PrimaryFluidDetails from "./PrimaryFluidDetails";
import PromagDetails from "./PromagDetails";
import ProsserDetails from "./ProsserDetails";
import WebsterDetails from "./WebsterDetails";
import WeinmanDetails from "./WeinmanDetails";
import WintersDetails from "./WintersDetails";

const sidebars = {
  armstrong: <ArmstrongSidebar />,
  aro: <AROSidebar />,
  "aw-lake": <AWLakeSidebar />,
  barnes: <BarnesSidebar />,
  burkert: <BurkertSidebar />,
  burks: <BurksSidebar />,
  caster: <CasterSidebar />,
  crane: <CraneSidebar />,
  crown: <CrownSidebar />,
  deming: <DemingSidebar />,
  flux: <FluxSidebar />,
  goulds: <GouldsSidebar />,
  grundfos: <GrundfosSidebar />,
  gusher: <GusherSidebar />,
  hays: <HaysSidebar />,
  hayward: <HaywardSidebar />,
  helwig: <HelwigSidebar />,
  kobold: <KoboldSidebar />,
  koflo: <KofloSidebar />,
  lovejoy: <LovejoySidebar />,
  "mcdonnell-miller": <McDonnellMillerSidebar />,
  midwest: <MidwestSidebar />,
  pacer: <PacerSidebar />,
  "primary-fluid": <PrimaryFluidSidebar />,
  promag: <PromagSidebar />,
  prosser: <ProsserSidebar />,
  webster: <WebsterSidebar />,
  weinman: <WeinmanSidebar />,
  winters: <WintersSidebar />,
};

class SupplierDetails extends React.Component {
  render() {
    return (
      <>
        <div className="section-full small-device  p-t80 p-b50 bg-white">
          <div className="section-content">
            <div className="container">
              <div className="row">
                {sidebars[this.props.name]}
                <Switch>
                  <Route
                    path="/suppliers/armstrong"
                    component={ArmstrongDetails}
                  />
                  <Route path="/suppliers/aro" component={ARODetails} />
                  <Route path="/suppliers/aw-lake" component={AWLakeDetails} />
                  <Route path="/suppliers/barnes" component={BarnesDetails} />
                  <Route path="/suppliers/burkert" component={BurkertDetails} />
                  <Route path="/suppliers/burks" component={BurksDetails} />
                  <Route path="/suppliers/caster" component={CasterDetails} />
                  <Route path="/suppliers/crane" component={CraneDetails} />
                  <Route path="/suppliers/crown" component={CrownDetails} />
                  <Route path="/suppliers/deming" component={DemingDetails} />
                  <Route path="/suppliers/flux" component={FluxDetails} />
                  <Route path="/suppliers/goulds" component={GouldsDetails} />
                  <Route
                    path="/suppliers/grundfos"
                    component={GrundfosDetails}
                  />
                  <Route path="/suppliers/gusher" component={GusherDetails} />
                  <Route path="/suppliers/hays" component={HaysDetails} />
                  <Route path="/suppliers/hayward" component={HaywardDetails} />
                  <Route path="/suppliers/helwig" component={HelwigDetails} />
                  <Route path="/suppliers/kobold" component={KoboldDetails} />
                  <Route path="/suppliers/koflo" component={KofloDetails} />
                  <Route path="/suppliers/lovejoy" component={LovejoyDetails} />
                  <Route
                    path="/suppliers/mcdonnell-miller"
                    component={McDonnellMillerDetails}
                  />
                  <Route path="/suppliers/midwest" component={MidwestDetails} />
                  <Route path="/suppliers/pacer" component={PacerDetails} />
                  <Route
                    path="/suppliers/primary-fluid"
                    component={PrimaryFluidDetails}
                  />
                  <Route path="/suppliers/promag" component={PromagDetails} />
                  <Route path="/suppliers/prosser" component={ProsserDetails} />
                  <Route path="/suppliers/webster" component={WebsterDetails} />
                  <Route path="/suppliers/weinman" component={WeinmanDetails} />
                  <Route path="/suppliers/winters" component={WintersDetails} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SupplierDetails;
