import React from "react";
import { NavLink } from "react-router-dom";

class PumpSidebar extends React.Component {
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12 rightSidebar">
          <div className="all_services service-side-btn m-b30 site-bg-secondry p-a20 text-white">
            <h2 className="wt-title">Suppliers</h2>
            <ul>
              <li>
                <NavLink to={"/products/pumps/armstrong"}>
                  Armstrong Pumps
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/aro"}>ARO Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/barnes"}>Barnes Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/burks"}>Burks Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/caster"}>Caster Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/crane"}>Crane Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/crown"}>Crown Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/deming"}>Deming Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/flux"}>Flux Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/goulds"}>
                  G&L Goulds Pumps
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/grundfos"}>
                  Grundfos Pumps
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/gusher"}>Gusher Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/helwig"}>Helwig Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/pacer"}>Pacer Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/promag"}>Promag Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/prosser"}>Prosser Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/weinman"}>Weinman Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pumps/webster"}>Webster Pumps</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default PumpSidebar;
