import React from "react";

export default function StrainerContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Hayward Industrial Strainers</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          At Hayward®, we’re more than just equipment. Our objective is to make
          your pool experience worry and hassle-free. That’s why our equipment
          is engineered to last and work smart at keeping your pool sparkling
          clean and trouble free. For over 80-years, we’ve been helping pool
          owners enjoy the pleasures of pool ownership by manufacturing cutting
          edge, technologically advanced pool equipment worldwide. We strive to
          ensure that your Totally Hayward™ System operates at maximum
          efficiency all season long. Our goal is that at the end of the day,
          your only concern is to turn your equipment on and off.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/hayward/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Pipeline Strainers From Hayward Industrial Products Inc. - Simplex
            Plastic Basket Strainers, Duplex Basket Strainers, Duplex Plastic 6
            - 8" Strainers, Plastic Y Strainers.
          </p>
        </div>
      </div>
      <div className="service-provide">
        <h2>Applications</h2>
        <p>
          Whether you are trying to create the perfect backyard environment,
          reduce operating and maintenance costs through the ease of wireless
          controls, Hayward is your single source solution. Our products include
          a complete line of technologically advanced:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Pumps</li>
              <li>Filters</li>
              <li>Heaters</li>
              <li>Heat pumps</li>
              <li>Automatic pool cleaners</li>
              <li>Lighting</li>
              <li>Controls</li>
              <li>Salt chlorine generators</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/hayward/hayward-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Strainer Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Simplex Plastic Basket Strainers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                When pipeline system components require protection from dirt and
                debris and the line can be shut down for basket cleaning, a
                HAYWARD® All Plastic Simplex Basket Strainer is the ideal
                choice.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Duplex Basket Strainers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                HAYWARD Duplex Basket Strainers can be installed either in-line
                or with loop piping configurations. This is made possible by
                changing the orientation of the bottom outlet connection.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Duplex Basket Strainers - 6" & 8"
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Plastic Baskets in perforation sizes from 1/32" to 3/16" are
                available. Stainless steel perforated baskets are available from
                1/2" to 1/32". Mesh stainless steel baskets for very fine
                straining applications are available in sizes from 20 mesh down
                to 325 mesh.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Plastic Y Strainers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Hayward Plastic Y Strainers protect piping system components
                from damage caused by dirt or debris in the process media. They
                cost less than other types of strainers and are light weight and
                very compact.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
