import React from "react";
import Footer from "../Parts/Footer";
import Header from "../Parts/Header";
import Banner from "../Segments/Banner";
import MarketSegment from "../Segments/Markets";

var bnrimg = require("../../images/BP-1920x960.png");

export default function Markets() {
  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Markets" pagename="Markets" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}
        <MarketSegment />
      </div>
      <Footer />
    </>
  );
}
