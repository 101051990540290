import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DemingContent from "./DemingPumpContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/suppliers/deming/pumps" component={DemingContent} />
          <Route path="/suppliers/deming">
            <Redirect to="/suppliers/deming/pumps" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
