import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Parts/Footer";
import Header from "../../Parts/Header";
import Banner from "../../Segments/Banner";
import SupplierDetails from "../../Segments/SupplierDetails";

var bnrimg = require("../../../images/BP-1920x960-1.png");

const suppliers = {
  armstrong: "Armstrong Pumps",
  aro: "ARO Pumps",
  "aw-lake": "AW-Lake",
  barnes: "Barnes Pumps",
  burkert: "Burkert Contromatic",
  burks: "Burks Pumps",
  caster: "Caster Pumps",
  crane: "Crane Pumps",
  crown: "Crown Pumps",
  deming: "Deming Pumps",
  flux: "Flux Pumps",
  goulds: "G&L Goulds Pumps",
  grundfos: "Grundfos Pumps",
  gusher: "Gusher Pumps",
  hays: "Hays Fluid Controls",
  hayward: "Hayward Industrial",
  helwig: "Helwig Pumps",
  kobold: "Kobold Instruments",
  koflo: "Koflo Static Mixers",
  lovejoy: "Lovejoy Couplings",
  "mcdonnell-miller": "McDonnell & Miller",
  midwest: "Midwest Mixing",
  pacer: "Pacer Pumps",
  "primary-fluid": "Primary Fluid Systems",
  promag: "Promag Pumps",
  prosser: "Prosser Pumps",
  webster: "Webster Pumps",
  weinman: "Weinman Pumps",
  winters: "Winters Instuments",
};

export default function Details() {
  let { name } = useParams();

  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner
          title={suppliers[name]}
          pagename="Suppliers"
          pagename2={suppliers[name]}
          bgimage={bnrimg}
        />
        {/* INNER PAGE BANNER END */}
        <SupplierDetails name={name} />
      </div>
      <Footer />
    </>
  );
}
