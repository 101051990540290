import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import HaysContent from "./HaysValveContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/suppliers/hays/valves" component={HaysContent} />
          <Route path="/suppliers/hays">
            <Redirect to="/suppliers/hays/valves" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
