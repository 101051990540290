import React from "react";

export default function ValveContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Hayward Industrial Valves</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          At Hayward®, we’re more than just equipment. Our objective is to make
          your pool experience worry and hassle-free. That’s why our equipment
          is engineered to last and work smart at keeping your pool sparkling
          clean and trouble free. For over 80-years, we’ve been helping pool
          owners enjoy the pleasures of pool ownership by manufacturing cutting
          edge, technologically advanced pool equipment worldwide. We strive to
          ensure that your Totally Hayward™ System operates at maximum
          efficiency all season long. Our goal is that at the end of the day,
          your only concern is to turn your equipment on and off.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/hayward/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Pipeline Strainers From Hayward Industrial Products Inc. - Simplex
            Plastic Basket Strainers, Duplex Basket Strainers, Duplex Plastic 6
            - 8" Strainers, Plastic Y Strainers.
          </p>
        </div>
      </div>
      <div className="service-provide">
        <h2>Applications</h2>
        <p>
          Whether you are trying to create the perfect backyard environment,
          reduce operating and maintenance costs through the ease of wireless
          controls, Hayward is your single source solution. Our products include
          a complete line of technologically advanced:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Pumps</li>
              <li>Filters</li>
              <li>Heaters</li>
              <li>Heat pumps</li>
              <li>Automatic pool cleaners</li>
              <li>Lighting</li>
              <li>Controls</li>
              <li>Salt chlorine generators</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/hayward/hayward-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Valve Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Manual Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                - True Union Ball Valves
                <br />
                - ProFile2 Control Valves
                <br />
                - Three-Way Ball Valves
                <br />
                - Three-Way Lateral Ball Valves
                <br />
                - QIC Ball Valves
                <br />
                - Diaphragm Valves
                <br />
                - Butterfly Valves - 1-1/2" to 12"
                <br />- Butterfly Valves - 14" to 24"
              </div>
            </div>
            <div className="panel wt-panel">
              <div className="acod-head">
                <h4 className="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseTwo5"
                    className="collapsed"
                    data-parent="#accordion5"
                  >
                    Check Valves
                    <span className="indicator">
                      <i className="fa fa-plus" />
                    </span>
                  </a>
                </h4>
              </div>
              <div id="collapseTwo5" className="acod-body collapse bg-gray">
                <div className="acod-content p-a15">
                  - True Union Ball Check Valves
                  <br />
                  - Swing Check Valves
                  <br />
                  - Y Check Valves
                  <br />- Y Check Valves Spring-Loaded
                </div>
              </div>
            </div>
            <div className="panel wt-panel">
              <div className="acod-head">
                <h4 className="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseThree5"
                    className="collapsed"
                    data-parent="#accordion5"
                  >
                    Control Valves
                    <span className="indicator">
                      <i className="fa fa-plus" />
                    </span>
                  </a>
                </h4>
              </div>
              <div id="collapseThree5" className="acod-body collapse bg-gray">
                <div className="acod-content p-a15">
                  - Angle Valves
                  <br />
                  - Needle Valves
                  <br />
                  - Pressure Relief Valves
                  <br />
                  - Pressure Regulating Valves
                  <br />
                  - True Union Air Release Valve
                  <br />- Universal Stopcocks
                </div>
              </div>
            </div>
            <div className="panel wt-panel">
              <div className="acod-head">
                <h4 className="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseFour5"
                    className="collapsed"
                    data-parent="#accordion5"
                  >
                    Automated Valves
                    <span className="indicator">
                      <i className="fa fa-plus" />
                    </span>
                  </a>
                </h4>
              </div>
              <div id="collapseFour5" className="acod-body collapse bg-gray">
                <div className="acod-content p-a15">
                  - Solenoid Valves
                  <br />
                  - Electrically Actuated True Union Ball Valves
                  <br />- Pneumatically Actuated Diaphragm Valves
                </div>
              </div>
            </div>
            <div className="panel wt-panel">
              <div className="acod-head">
                <h4 className="acod-title">
                  <a
                    data-toggle="collapse"
                    href="#collapseFive5"
                    className="collapsed"
                    data-parent="#accordion5"
                  >
                    Actuator Ready Valves
                    <span className="indicator">
                      <i className="fa fa-plus" />
                    </span>
                  </a>
                </h4>
              </div>
              <div id="collapseFive5" className="acod-body collapse bg-gray">
                <div className="acod-content p-a15">
                  - True Union Ball Valves - Actuator Ready
                  <br />
                  - Butterfly Valves - Actuator Ready
                  <br />
                  - Actuator-Ready Three-Way Ball Valves
                  <br />- ProFile2 Ball Valves - Actuator Ready
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
