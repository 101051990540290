import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/oil-gas/bg-1.png");

class WhatWeDo3 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b50 bg-white bg-bottom-center bg-repeat-x"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            <div className="section-content">
              <div className="row d-flex justify-content-center flex-wrap">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="arc-home-about-right masonry-wrap">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 masonry-item">
                        <div className="arc-about-year text-uppercase text-right m-b20">
                          <div className="arc-about-year-pic">
                            <img
                              src={require("./../../images/BP-582x526.png")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 masonry-item">
                        <div className="wt-media m-b20  img-reflection">
                          <img
                            src={require("./../../images/BP-582x700.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 masonry-item">
                        <div className="wt-media m-b20  img-reflection">
                          <img
                            src={require("./../../images/BP-582x700-1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 masonry-item">
                        <div className="wt-media m-b20  img-reflection">
                          <img
                            src={require("./../../images/BP-582x526-1.png")}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b40">
                  <div className="whatWedo-info-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>What We do</div>
                        <div className="sep-leaf-right" />
                      </div>
                      <h2>Providing high-quality industrial pumps & more</h2>
                      <p>
                        The products we carry are good for a variety of
                        applications. We choose only items from suppliers that
                        we trust, including nationally trusted names like
                        Hayward Industrial, Armstrong Pumps, and others.
                      </p>
                    </div>
                    {/* TITLE END*/}
                    <ul className="site-list-style-one">
                      <li>QA & Testing, 100% Satisfaction Guarantee</li>
                      <li>
                        Unrivalled Workmanship, Professional and Qualified
                      </li>
                      <li>Large range of industrial pumps and accessories</li>
                    </ul>
                    <div className="welcom-to-section-bottom d-flex justify-content-between">
                      <div className="welcom-btn-position">
                        <NavLink
                          to={"/about"}
                          className="site-button-secondry site-btn-effect"
                        >
                          About Us
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo3;
