import React from "react";
import Header from "../../Parts/Header";
import Footer from "../../Parts/Footer";
import Banner from "../../Segments/Banner";
import SupplierSegment from "../../Segments/Suppliers";

var bnrimg = require("../../../images/BP-1920x960-1.png");

export default function Suppliers() {
  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Suppliers" pagename="Suppliers" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}
        <SupplierSegment />
      </div>
      <Footer />
    </>
  );
}
