import React from "react";

export default function BoilerControlContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">
          150E for Commercial and Industrial Steam Boilers
        </h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          The 150E conductance probe-type low water cut-off/pump controller
          compensates for shifting water levels, preventing rapid burner and
          pump cycling. This means you get more efficient operation from your
          boiler as system demands change.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/mcdonnell-miller/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            The 150E is the newest member of our 150 family of controls. Current
            150 and 157 cast-iron bodies can be easily upgraded with a 150E
            replacement head assembly to gain all the benefits of the 150E.
          </p>
        </div>
      </div>
      <div className="service-provide">
        <h2>Additional Features</h2>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>CSD-1 compliant</li>
              <li>Mercury-free</li>
              <li>Internal diagnostics to monitor operation</li>
              <li>1 HP switching relays</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/mcdonnell-miller/mcdonnell-miller-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
