import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PacerContent from "./PacerPumpContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route path="/suppliers/pacer" component={PacerContent} />
          <Route path="/suppliers/pacer">
            <Redirect to="/suppliers/pacer/pumps" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
