import React from "react";
import { NavLink } from "react-router-dom";

const filters = [
  { label: "Pumps", filter: ".cat-1" },
  { label: "Boiler Controls", filter: ".cat-2" },
  { label: "Couplings", filter: ".cat-3" },
  { label: "Flow Meters", filter: ".cat-4" },
  { label: "Mixers", filter: ".cat-5" },
  { label: "Pump Accessories", filter: ".cat-6" },
  { label: "Pressure Instruments", filter: ".cat-7" },
  { label: "Strainers", filter: ".cat-8" },
  { label: "Valves", filter: ".cat-9" },
];

const projects = [
  {
    image: require("./../../images/suppliers/armstrong.png"),
    title: "Armstrong Pumps",
    description:
      "Armstrong is a world leader in the development of innovative flow solutions.",
    filters: "cat-1",
    ref: "armstrong",
  },
  {
    image: require("./../../images/suppliers/aro.png"),
    title: "ARO Pumps",
    description:
      "Ingersoll Rand / ARO Diaphragm Pumps provide stall-free, ice-free operation.",
    filters: "cat-1",
    ref: "aro",
  },
  {
    image: require("./../../images/suppliers/aw-lake.png"),
    title: "AW-Lake",
    description:
      "AW-Lake offers a wide selection of Flow Technologies – Coriolis, Gear, Turbine, etc.",
    filters: "cat-4",
    ref: "aw-lake",
  },
  {
    image: require("./../../images/suppliers/barnes.png"),
    title: "Barnes Pumps",
    description:
      "Barnes Pumps provide versatility, high performance and unequaled value.",
    filters: "cat-1",
    ref: "barnes",
  },
  {
    image: require("./../../images/suppliers/burkert.png"),
    title: "Burkert Contromatic",
    description:
      "Burkert aims to provide complete solutions that meet the needs of end-users.",
    filters: "cat-4 cat-9",
    ref: "burkert",
  },
  {
    image: require("./../../images/suppliers/burks.png"),
    title: "Burks Pumps",
    description:
      "All Burks Pumps are offered in either close-coupled or frame-mounted designs.",
    filters: "cat-1",
    ref: "burks",
  },
  {
    image: require("./../../images/suppliers/warrender.png"),
    title: "Caster Pumps",
    description:
      "Caster Pump specializes in advanced, hermetically-sealed pump systems.",
    filters: "cat-1",
    ref: "caster",
  },
  {
    image: require("./../../images/suppliers/crane.png"),
    title: "Crane Pumps",
    description:
      "Crane Pumps serves the diverse needs for pump equipment throughout Canada.",
    filters: "cat-1",
    ref: "crane",
  },
  {
    image: require("./../../images/suppliers/crown.png"),
    title: "Crown Pumps",
    description:
      "Crown Pumps save users money with efficiency, low capital and serviceability.",
    filters: "cat-1",
    ref: "crown",
  },
  {
    image: require("./../../images/suppliers/deming.png"),
    title: "Deming Pumps",
    description:
      "Deming manufactures some of the most complete and diversified pump equipment.",
    filters: "cat-1",
    ref: "deming",
  },
  {
    image: require("./../../images/suppliers/flux.png"),
    title: "Flux Pumps",
    description:
      "Flux Pumps is the leader in drum and container transfer pump technology.",
    filters: "cat-1 cat-4 cat-5",
    ref: "flux",
  },
  {
    image: require("./../../images/suppliers/goulds.png"),
    title: "G&L Goulds Pumps",
    description:
      "G&L is a worldwide leader in centrifugal pumps and ancillary components.",
    filters: "cat-1",
    ref: "goulds",
  },
  {
    image: require("./../../images/suppliers/grundfos.png"),
    title: "Grundfos Pumps",
    description:
      "Grundfos aims to provide efficient and reliable pumps, globally.",
    filters: "cat-1",
    ref: "grundfos",
  },
  {
    image: require("./../../images/suppliers/gusher.png"),
    title: "Gusher Pumps",
    description:
      "Gusher Pumps has the largest selection of pumps available from one source.",
    filters: "cat-1",
    ref: "gusher",
  },
  {
    image: require("./../../images/suppliers/hays.png"),
    title: "Hays Fluid Controls",
    description:
      "Hays Fluid Controls manufactures valves for a wide range of differential pressures.",
    filters: "cat-9",
    ref: "hays",
  },
  {
    image: require("./../../images/suppliers/hayward.png"),
    title: "Hayward Industrial",
    description:
      "Hayward Industrial carries an extensive line of valves and flow control products",
    filters: "cat-8 cat-9",
    ref: "hayward",
  },
  {
    image: require("./../../images/suppliers/helwig.png"),
    title: "Helwig Pumps",
    description:
      "Helwig pumps are ideal for use in chemical feed to boilers or waste water treatment",
    filters: "cat-1",
    ref: "helwig",
  },
  {
    image: require("./../../images/suppliers/kobold.png"),
    title: "Kobold Instruments",
    description:
      "Kobold has been a world leader in process measurement and control solutions.",
    filters: "cat-4 cat-7",
    ref: "kobold",
  },
  {
    image: require("./../../images/suppliers/koflo.png"),
    title: "Koflo Static Mixers",
    description:
      "Koflo has optimized the design of static mixers to achieve the best results.",
    filters: "cat-5",
    ref: "koflo",
  },
  {
    image: require("./../../images/suppliers/lovejoy.png"),
    title: "Lovejoy Couplings",
    description:
      "Lovejoy Couplings is a premier supplier of shaft couplings and other components.",
    filters: "cat-3",
    ref: "lovejoy",
  },
  {
    image: require("./../../images/suppliers/mcdonnell-miller.png"),
    title: "McDonnell & Miller",
    description:
      "McDonnell & Miller specially designs to protect boilers from low water conditions.",
    filters: "cat-2",
    ref: "mcdonnell-miller",
  },
  {
    image: require("./../../images/suppliers/midwest-mixing.png"),
    title: "Midwest Mixing",
    description:
      "Midwest Mixing, Inc. are manufacturers of a complete line of mixers since 1946.",
    filters: "cat-5",
    ref: "midwest",
  },
  {
    image: require("./../../images/suppliers/pacer.png"),
    title: "Pacer Pumps",
    description:
      "Pacer Pumps provides a variety of pumps for the industrial market.",
    filters: "cat-1",
    ref: "pacer",
  },
  {
    image: require("./../../images/suppliers/primary-fluid-systems.png"),
    title: "Primary Fluid Systems",
    description:
      "Primary Fluid Systems' innovative designs ensure the best product for the application.",
    filters: "cat-6 cat-9",
    ref: "primary-fluid",
  },
  {
    image: require("./../../images/suppliers/promag.png"),
    title: "Promag Pumps",
    description:
      "Promag allows for retrofitting existing systems without costly modifications.",
    filters: "cat-1",
    ref: "promag",
  },
  {
    image: require("./../../images/suppliers/prosser.png"),
    title: "Prosser Pumps",
    description:
      "Prosser pumps are the most cost-effective choice for efficient, reliable dewatering.",
    filters: "cat-1",
    ref: "prosser",
  },
  {
    image: require("./../../images/suppliers/webster.png"),
    title: "Webster Pumps",
    description:
      "Webster includes a complete line of technologically advanced pumps.",
    filters: "cat-1",
    ref: "webster",
  },
  {
    image: require("./../../images/suppliers/weinman.png"),
    title: "Weinman Pumps",
    description:
      "Weinman pumps are particularly adept in HVAC and water service applications.",
    filters: "cat-1",
    ref: "weinman",
  },
  {
    image: require("./../../images/suppliers/winters.png"),
    title: "Winters Instruments",
    description:
      "Winters' product line includes transmitters, thermometers, seals, and more.",
    filters: "cat-7",
    ref: "winters",
  },
];

class Suppliers extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        // document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }

  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white">
          <div className="container">
            {/* PAGINATION START */}
            <div className="filter-wrap work-grid p-b30 text-center">
              <ul className="masonry-filter link-style ">
                <li className="active">
                  <NavLink to={"#"} data-filter="*">
                    All
                  </NavLink>
                </li>
                {filters.map((item, index) => (
                  <li key={index}>
                    <NavLink to={"#"} data-filter={item.filter}>
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            {/* PAGINATION END */}
            {/* GALLERY CONTENT START */}
            <div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filters} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}
                >
                  <div className="project-img-effect-1">
                    <img src={item.image} alt="" />
                    <div className="wt-info">
                      <h3 className="wt-tilte m-b10 m-t0">{item.title}</h3>
                      <p>{item.description}</p>
                      <NavLink
                        to={`/suppliers/${item.ref}`}
                        className="site-button-link"
                      >
                        View Products
                      </NavLink>
                    </div>
                    <a href={item.image} className="mfp-link">
                      <i className="fa fa-search-plus" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
      </>
    );
  }
}

export default Suppliers;
