import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import KoboldContent from "./KoboldFlowMeterContent";
import KoboldPressureInstrumentContent from "./KoboldPressureInstrumentContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/suppliers/kobold/flow-meters"
            component={KoboldContent}
          />
          <Route
            path="/suppliers/kobold/pressure-instruments"
            component={KoboldPressureInstrumentContent}
          />
          <Route path="/suppliers/kobold">
            <Redirect to="/suppliers/kobold/flow-meters" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
