import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ArmstrongPumpContent from "./ArmstrongPumpContent";
import AROPumpContent from "./AROPumpContent";
import BarnesPumpContent from "./BarnesPumpContent";
import BurksPumpContent from "./BurksPumpContent";
import CasterPumpContent from "./CasterPumpContent";
import CranePumpContent from "./CranePumpContent";
import CrownPumpContent from "./CrownPumpContent";
import DemingPumpContent from "./DemingPumpContent";
import FlexPumpContent from "./FluxPumpContent";
import GouldsPumpContent from "./GouldsPumpContent";
import GrundfosPumpContent from "./GrundfosPumpContent";
import GusherPumpContent from "./GusherPumpContent";
import HelwigPumpContent from "./HelwigPumpContent";
import PacerPumpContent from "./PacerPumpContent";
import PromagPumpContent from "./PromagPumpContent";
import ProsserPumpContent from "./ProsserPumpContent";
import WeinmanPumpContent from "./WeinmanPumpContent";
import WebsterPumpContent from "./WebsterPumpContent";

class PumpDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/pumps/armstrong"
            component={ArmstrongPumpContent}
          />
          <Route path="/products/pumps/aro" component={AROPumpContent} />
          <Route path="/products/pumps/barnes" component={BarnesPumpContent} />
          <Route path="/products/pumps/burks" component={BurksPumpContent} />
          <Route path="/products/pumps/caster" component={CasterPumpContent} />
          <Route path="/products/pumps/crane" component={CranePumpContent} />
          <Route path="/products/pumps/crown" component={CrownPumpContent} />
          <Route path="/products/pumps/deming" component={DemingPumpContent} />
          <Route path="/products/pumps/flux" component={FlexPumpContent} />
          <Route path="/products/pumps/goulds" component={GouldsPumpContent} />
          <Route
            path="/products/pumps/grundfos"
            component={GrundfosPumpContent}
          />
          <Route path="/products/pumps/gusher" component={GusherPumpContent} />
          <Route path="/products/pumps/helwig" component={HelwigPumpContent} />
          <Route path="/products/pumps/pacer" component={PacerPumpContent} />
          <Route path="/products/pumps/promag" component={PromagPumpContent} />
          <Route
            path="/products/pumps/prosser"
            component={ProsserPumpContent}
          />
          <Route
            path="/products/pumps/weinman"
            component={WeinmanPumpContent}
          />
          <Route
            path="/products/pumps/webster"
            component={WebsterPumpContent}
          />
          <Route path="/products/pumps">
            <Redirect to="/products/pumps/armstrong" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default PumpDetails;
