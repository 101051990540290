import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Segments/Switcher";

var bgimage = require("./../../images/background/footer-bg.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.currentstate = {
      logo: require("./../../images/BPINC-WEB-FOOTER.svg").default,
    };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large footer-dark text-white footer-style1">
          {/* FOOTER BLOCKES START */}
          <div
            className="footer-top bg-no-repeat bg-bottom-right"
            style={{ backgroundImage: "url(" + bgimage + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="footer-h-left">
                    <div className="widget widget_about">
                      <div className="logo-footer clearfix">
                        <NavLink to={"./"}>
                          <img src={this.currentstate.logo} alt="" />
                        </NavLink>
                      </div>
                      <p>
                        With over 25 years of experience and knowledge in the
                        industry, contact us today to place your order for
                        industrial pumps, valves, mixers, and other essential
                        components. We proudly serve clients from our base of
                        operations in Ontario, Canada.
                      </p>
                    </div>
                    <div className="widget recent-posts-entry">
                      <ul className="widget_address">
                        <li>
                          <i className="fa fa-map-marker" />
                          3325 North Service Rd. Unit 106, Burlington, Ontario,
                          Canada, L7N 3G2
                        </li>
                        <li>
                          <i className="fa fa-envelope" />
                          bpump@burlingtonpump.com
                        </li>
                        <li>
                          <i className="fa fa-phone" />
                          +1 (888) 339-2060 [TOLL FREE]
                        </li>
                        <li>
                          <i className="fa fa-phone" />
                          +1 (905) 631-7672
                        </li>
                      </ul>
                    </div>
                    <ul className="social-icons  wt-social-links footer-social-icon">
                      <li>
                        <a
                          className="fa fa-linkedin"
                          target="_blank"
                          href="https://www.linkedin.com/company/burlington-pump-inc"
                        />
                      </li>
                      <li>
                        <a
                          className="fa fa-facebook"
                          target="_blank"
                          href="https://m.facebook.com/people/Burlington-Pump-Inc/100066951787067/"
                        />
                      </li>
                      <li>
                        <a
                          className="fa fa-instagram"
                          target="_blank"
                          href="https://www.instagram.com/burlingtonpumpinc"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="row footer-h-right">
                    <div className="col-lg-5 col-md-4">
                      <div className="widget widget_services">
                        <h3 className="widget-title">Useful links</h3>
                        <ul>
                          <li>
                            <NavLink to={"/markets"}>Markets</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/suppliers"}>Suppliers</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/about"}>About</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/contact/quote"}>
                              Quote Request
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/contact/information"}>
                              Information Request
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                      <div className="widget widget_services">
                        <h3 className="widget-title">Our Products</h3>
                        <ul>
                          <li>
                            <NavLink to={"/products/pumps"}>
                              Industrial Pumps
                            </NavLink>
                            <NavLink to={"/products/controls"}>
                              Boiler & Level Controls
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/products/couplings"}>
                              Couplings
                            </NavLink>
                            <NavLink to={"/products/flow-meters"}>
                              Flow Meters
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/products/mixers"}>
                              Industrial Mixers
                            </NavLink>
                            <NavLink to={"/products/accessories"}>
                              Pump Accessories
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/products/pressure-instruments"}>
                              Pressure Instruments
                            </NavLink>
                            <NavLink to={"/products/strainers"}>
                              Strainers
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/products/valves"}>Valves</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="widget widget_newsletter">
                    <h3 className="widget-title">Newsletter</h3>
                    <p>
                      Subscribe to our newsletter to receive latest news on our
                      services.
                    </p>
                    <div className="newsletter-input">
                      <div className="input-group">
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Enter your email"
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text nl-search-btn text-black site-bg-primary title-style-2"
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom">
            <div className="container">
              <div className="wt-footer-bot-left d-flex justify-content-between">
                <span className="copyrights-text">
                  Copyright © 2023{" "}
                  <span className="site-text-primary">
                    Burlington Pump Inc.
                  </span>
                </span>
                <ul className="copyrights-nav">
                  <li>
                    <a
                      href={
                        "https://burlingtonpump.com/BPI-Terms-and-Conditions.pdf"
                      }
                    >
                      Terms &amp; Condition
                    </a>
                  </li>
                  <li>
                    <NavLink to={"/contact"}>Contact Us</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
