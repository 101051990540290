import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrimaryFluidContent from "./PrimaryFluidPumpAccessoryContent";
import PrimaryFluidValveContent from "./PrimaryFluidValveContent";

class SupplierDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/suppliers/primary-fluid/pump-accessories"
            exact
            component={PrimaryFluidContent}
          />
          <Route
            path="/suppliers/primary-fluid/valves"
            exact
            component={PrimaryFluidValveContent}
          />
          <Route path="/suppliers/primary-fluid">
            <Redirect to="/suppliers/primary-fluid/pump-accessories" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default SupplierDetails;
