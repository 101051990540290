import Components from "./components/Components";
import ScrollToTop from "./components/Segments/ScrollToTop";
import Loader from "./components/Segments/Loader";

function App() {
  return (
    <div className="App">
      <Components />
      <ScrollToTop />
      <Loader />
    </div>
  );
}

export default App;
