import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import KoboldPressureInstrumentContent from "./KoboldPressureInstrumentContent";
import WintersPressureInstrumentContent from "./WintersPressureInstrumentContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/pressure-instruments/kobold"
            component={KoboldPressureInstrumentContent}
          />
          <Route
            path="/products/pressure-instruments/winters"
            component={WintersPressureInstrumentContent}
          />
          <Route path="/products/pressure-instruments">
            <Redirect to="/products/pressure-instruments/kobold" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
