import React from "react";

import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import AboutSegment from "../Segments/About";
import Essentials from "../Segments/Essentials";
import Statistics from "../Segments/Statistics";
import Team from "../Segments/Team";
import ClientsLogo from "../Segments/ClientsLogo";

var bnrimg = require("../../images/BP-1920x960-1.png");

export default function About() {
  return (
    <>
      <Header />
      <div className="page-content">
        <Banner title="About Us" pagename="About Us" bgimage={bnrimg} />
        <AboutSegment />
        <Essentials />
        <Statistics />
        <Team />
        <ClientsLogo />
      </div>
      <Footer />
    </>
  );
}
