import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact/Contact";
import Products from "./Pages/Products/Products";
import Suppliers from "./Pages/Suppliers/Suppliers";
import About from "./Pages/About";
import Quote from "./Pages/Contact/Quote";
import Information from "./Pages/Contact/Information";
import ProductDetails from "./Pages/Products/Details";
import SupplierDetails from "./Pages/Suppliers/Details";
import Markets from "./Pages/Markets";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/bdUGQm" exact>
              <Redirect to="/" />
            </Route>
            <Route path="/about" exact component={About} />
            <Route path="/contact" exact component={Contact} />
            <Route path="/contact/quote" exact component={Quote} />
            <Route path="/contact/information" exact component={Information} />
            <Route path="/products" exact component={Products} />
            <Route path="/products/:type" component={ProductDetails} />
            <Route path="/markets" exact component={Markets} />
            <Route path="/suppliers" exact component={Suppliers} />
            <Route path="/suppliers/:name" component={SupplierDetails} />
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
