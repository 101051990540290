import React from "react";
import { NavLink } from "react-router-dom";

class ServicesSidebar extends React.Component {
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12 rightSidebar">
          <div className="all_services p-a20 text-white">
            <h2 className="wt-title">Suppliers</h2>
            <ul>
              <li>
                <NavLink to={"/products/couplings"}>Lovejoy Couplings</NavLink>
              </li>
            </ul>
          </div>
          <div className="service-side-btn m-b30 site-bg-secondry p-a20 text-white">
            <h2 className="wt-title">Catalogues</h2>
            <p>View Lovejoy's Engineering Data Catalogue.</p>
            <div className="wt-icon-box-wraper left ">
              <NavLink to={"#"} className="btn-block  p-a15 m-tb10">
                <span className="text-black m-r10">
                  <i className="fa fa-file-pdf-o" />
                </span>
                <strong className="text-uppercase text-black">
                  Download .PDF
                </strong>
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesSidebar;
