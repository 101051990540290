import React from "react";
import { NavLink } from "react-router-dom";

class ServicesSidebar extends React.Component {
  render() {
    return (
      <>
        <div className="col-lg-4 col-md-12 rightSidebar">
          <div className="all_services service-side-btn m-b30 site-bg-secondry p-a20 text-white">
            <h2 className="wt-title">Suppliers</h2>
            <ul>
              <li>
                <NavLink to={"/products/mixers/flux"}>Flux Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/mixers/midwest"}>
                  Midwest Mixing Inc.
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/mixers/koflo"}>
                  Koflo Static Mixers
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesSidebar;
