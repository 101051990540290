import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Footer from "../../Parts/Footer";
import Header from "../../Parts/Header";
import Banner from "../../Segments/Banner";

var bnr1 = require("../../../images/BP-1900x1060.png");
var bgimg = require("../../../images/background/bg-map2.png");
var bnrimg = require("../../../images/BP-1920x960.png");

export default function Information() {
  const [success, setSuccess] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "bpump",
        "information_request",
        form.current,
        "o5QeFyxFsioMqaePy"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(result.text);
        },
        (error) => {
          console.log(error.text);
          setSuccess(error.text);
        }
      );
  };

  return (
    <>
      <Header />
      <div className="page-content">
        {/* INNER PAGE BANNER */}
        <Banner title="Contact Us" pagename="Contact Us" bgimage={bnrimg} />
        {/* INNER PAGE BANNER END */}
        {/* SECTION CONTENTG START */}
        {/* CONTACT FORM */}
        <div
          className="section-full  p-t80 p-b50 bg-cover"
          style={{
            backgroundImage: "url(" + bnr1 + ")",
          }}
        >
          <div className="section-content">
            <div className="container">
              <div className="contact-one">
                {/* CONTACT FORM*/}
                <div className="row  d-flex justify-content-center flex-wrap">
                  <div
                    className="col-lg-6 col-md-6 m-b30"
                    style={{ backgroundColor: "#fff" }}
                  >
                    {success === "" ? (
                      <form
                        className="cons-contact-form"
                        method="post"
                        action="#"
                        ref={form}
                        onSubmit={sendEmail}
                      >
                        {/* TITLE START */}
                        <div className="section-head left wt-small-separator-outer">
                          <div className="wt-small-separator site-text-primary">
                            <div className="sep-leaf-left" />
                            <div>Contact Form</div>
                            <div className="sep-leaf-right" />
                          </div>
                          <h2>Get In Touch</h2>
                        </div>
                        {/* TITLE END */}
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <input
                                name="user_name"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Name"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <input
                                name="user_email"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <input
                                name="user_phone"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <input
                                name="subject"
                                type="text"
                                className="form-control"
                                required
                                placeholder="Subject"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control"
                                rows={4}
                                placeholder="Message"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <input
                              type="submit"
                              className="site-button site-btn-effect"
                              value="Submit Now"
                            />
                          </div>
                        </div>
                      </form>
                    ) : success === "OK" ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Thank you! We will get back to you shortly</h4>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>Something went wrong, please try again shortly.</h4>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 m-b30">
                    <div className="contact-info">
                      <div className="contact-info-inner">
                        {/* TITLE START*/}
                        <div className="section-head left wt-small-separator-outer">
                          <div className="wt-small-separator site-text-primary">
                            <div className="sep-leaf-left" />
                            <div>Contact Info</div>
                            <div className="sep-leaf-right" />
                          </div>
                          <h2>Our Full Info</h2>
                        </div>
                        {/* TITLE END*/}
                        <div
                          className="contact-info-section"
                          style={{ backgroundImage: "url(" + bgimg + ")" }}
                        >
                          <div className="wt-icon-box-wraper left m-b30">
                            <div className="icon-content">
                              <h3 className="m-t0 site-text-primary">Phone</h3>
                              <p>+1 (888) 339-2060 [TOLL FREE]</p>
                              <p>+1 (905) 631-7672</p>
                            </div>
                          </div>
                          <div className="wt-icon-box-wraper left m-b30">
                            <div className="icon-content">
                              <h3 className="m-t0 site-text-primary">Email</h3>
                              <p>bpump@burlingtonpump.com</p>
                            </div>
                          </div>
                          <div className="wt-icon-box-wraper left m-b30">
                            <div className="icon-content">
                              <h3 className="m-t0 site-text-primary">
                                Address
                              </h3>
                              <p>
                                3325 North Service Rd. Unit 106, Burlington,
                                Ontario, Canada, L7N 3G2
                              </p>
                            </div>
                          </div>
                          <div className="wt-icon-box-wraper left">
                            <div className="icon-content">
                              <h3 className="m-t0 site-text-primary">
                                Opening Hours
                              </h3>
                              <ul className="list-unstyled m-b0">
                                <li>Monday - Friday: 8AM – 5PM</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
