import React from "react";

export default function FlowMeterContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">AW-Lake Flow Meters</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          At AW-Lake Company, we constantly strive to produce the highest
          quality flow measurement instrumentation for our customers, from the
          smallest flow meter order to the most comprehensive OEM flow meter
          solutions customized for specific applications. Our dedication to
          exceeding customer expectations is evident throughout our
          organization. We are part of a Global Network of world-class flow
          meter manufacturers, producing a wide variety of flow meter
          technologies, flow electronics, and wireless networks.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/aw-lake/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Our team at AW-Lake Company is made up of uniquely talented
            individuals. It is their collective passion for excellence that
            drives our success. It takes all of us: Manufacturing personnel,
            service technicians, administrative staff, and sales professionals
            dedicated to quality, reliability, efficiency, and above all
            customer service. Every person takes care of customer inquiries, and
            we are committed to providing our customers with personal
            experiences with real people, not an automated system.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Together, we work hard to supply our customers with the flow
              metering solution they need on budget, on time, and of the highest
              quality.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>AW-LAKE TEAM</strong>
            </div>
          </blockquote>
        </div>
      </div>
      <div>
        <h2>Flow Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Flow Meters
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Value priced flow meters and guaranteed to perform, Lake flow
                meters are perfect for a variety of water, oil and air/gas flow
                meter applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  ClearView Flow Meter
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Economical flow meters to monitor municipal-pressure water
                flows, observe case drain flows and verify pump outputs.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Case Drain Monitor
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lake Monitors Case Drain Monitor is an affordable option to
                using standard flows meter for case drain applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flow Meter Alarms
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Utilized in applications such as mobile hydraulic equipment and
                industrial process control to ensure sufficient flows of
                coolants and lubricants.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flow Meter Transmitters
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Ideal for batching, industrial process control, mobile hydraulic
                equipment and computer / PLC-controlled hydraulic systems
                monitoring application.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  FlowStat Sensor (Stainless Steel)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Perfect monitoring solution for chiller / cooling circuits,
                HVAC, medical equipment, batching and industrial process control
                applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  FlowStat ES Sensor (Plastic)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Perfect monitoring solution for chiller / cooling circuits,
                HVAC, medical equipment, batching and industrial process control
                applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  FreeFlow Sensor
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Minimally invasive, low cost segmented wedge flow sensor. Used
                to monitor and control process water.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flow Analyzers (R100 & R/T100)
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Integrates with Lake Monitors flow rate transmitters and
                FlowStat® to form a powerful flow control and measurement
                system.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  K & T Series Analyzer
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Diagnose faults in hydraulic circuits, determine hydraulic
                horsepower and test for component wear such as hydraulic valve
                and cylinder leakage.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  WTA Series Analyzer
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Diagnose faults in hydraulic circuits, determine hydraulic
                horsepower and test for component wear such as hydraulic valve
                and cylinder leakage.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Weld Shield Gas Flow Switch
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Used to detect insufficient flow conditions for automated or
                controlled shielded welding processes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
