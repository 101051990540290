import React from "react";
import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Slider from "../Segments/Slider";
import Products from "../Segments/Solutions";
import WhatWeDo from "../Segments/WhatWeDo";
import Mission from "../Segments/Mission";
import Testimonials from "../Segments/Testimonials";
import Statistics from "../Segments/Statistics";
import ClientsLogo from "../Segments/ClientsLogo";

class Home7 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Slider />
          <ClientsLogo />
          <WhatWeDo />
          <Products />
          <Mission />
          <Testimonials />
          <Statistics />
          <ClientsLogo />
        </div>
        <Footer />
      </>
    );
  }
}

export default Home7;
