import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const testimonials = [
  {
    image: require("./../../images/BP-190x190.png"),
    reviewername: "Angela Piechota",
    position: "Store Owner",
    review:
      "One of our pump capacitors had stopped working and we desperately needed a replacement. Burlington Pump was not only quick, but extremely helpful throughout the entire process!",
  },
  {
    image: require("./../../images/BP-190x190-1.png"),
    reviewername: "Avery Douglas",
    position: "Contractor",
    review:
      "I had a client who needed a replacement pump and was looking for days for the specific requirements I had. I finally found exactly what I was looking for all in one place!! Great customer service and super quick.",
  },
  {
    image: require("./../../images/BP-190x190-1.png"),
    reviewername: "Farhaan Khan",
    position: "Store Owner",
    review:
      "A simple and fast experience. I have no idea about pumps so when mine broke at the store I was completely lost. Thank you for your service, and for making sure my business can still run!",
  },
];

var bnr1 = require("./../../images/BP-1900x1060.png");

class Testimonials6 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      nav: false,
      dots: true,
      margin: 30,
      navText: [
        '<i class="fa fa-long-arrow-left"></i>',
        '<i class="fa fa-long-arrow-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
        1400: {
          items: 2,
        },
      },
    };
    return (
      <>
        <div
          className="section-full  p-t80 p-b50 bg-no-repeat bg-center bg-gray"
          style={{ backgroundImage: "url(" + bnr1 + ")" }}
        >
          <div className="container">
            {/* TITLE START*/}
            <div className="section-head center wt-small-separator-outer">
              <div className="wt-small-separator site-text-primary">
                <div className="sep-leaf-left" />
                <div>What our clients say</div>
                <div className="sep-leaf-right" />
              </div>
              <h2>Testimonials</h2>
            </div>
            {/* TITLE END*/}
            <div className="testimonial-2-content-outer">
              <OwlCarousel
                className="testimonial-3-content owl-carousel  owl-btn-vertical-center long-arrow-next-prev"
                {...options}
              >
                {testimonials.map((item, index) => (
                  <div key={index} className="item">
                    <div className="testimonial-2 bg-white">
                      <div className="testimonial-content">
                        <div className="testimonial-text">
                          <i className="fa fa-quote-left" />
                          <p>{item.review}</p>
                        </div>
                        <div className="testimonial-detail clearfix">
                          <div className="testimonial-pic-block">
                            <div className="testimonial-pic">
                              <img src={item.image} alt="" />
                            </div>
                          </div>
                          <div className="testimonial-info">
                            <span className="testimonial-name  title-style-2 site-text-secondry">
                              {item.reviewername}
                            </span>
                            <span className="testimonial-position title-style-2 site-text-primary">
                              {item.position}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonials6;
