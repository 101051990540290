import React from "react";

export default function ValveContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Burkert Valves</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Burkert Canada Fluid Control Systems can be used wherever fluid media
          and gases need to be measured, controlled and regulated. Whether the
          application is filling, level, flow, pressure or temperature we have a
          solution and a uniquely comprehensive range of products to handle it,
          including solenoid, process and analytical valves, pneumatic
          actuation, sensors and controllers.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/burkert/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            For Burkert Canada it is not enough to simply offer individual
            products. Burkert Canada Fluid Control Systems aim is to provide
            complete system and application solutions that meet the specific
            needs of our customers. Tell us what you need and our engineers will
            find an appropriate solution using our vast experience and a wide
            range of services such as advice and engineering, installation,
            testing, and after-sales support.
          </p>
        </div>
      </div>
      <div>
        <h2>Valve Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Angle Seat Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2000</td>
                    <td>
                      2/2 Way Angle-Seat Valve, DN 13-65, Pneumatically
                      Operated, Gunmetal or Stainless Steel Body
                    </td>
                  </tr>
                  <tr>
                    <td>2100</td>
                    <td>
                      2/2-way Angle-Seat Valve with stainless steel design for
                      media up to +180°C, DN 13-50. Combinable as On/Off System
                      Type 8801-YE
                    </td>
                  </tr>
                  <tr>
                    <td>2300</td>
                    <td>
                      2/2-way Angle-Seat Control Valve with stainless steel
                      design for media up to +180°C, DN 13-50. Combinable as
                      Continuous System Type 8802-YG
                    </td>
                  </tr>
                  <tr>
                    <td>2702</td>
                    <td>
                      2/2-Way Angle Seat Control Valve, Pneumatically Operated,
                      Stainless Steel Body, DN 13-50
                    </td>
                  </tr>
                  <tr>
                    <td>8801YA</td>
                    <td> Classic On/Off System, angle-seat valve</td>
                  </tr>
                  <tr>
                    <td>8801YE</td>
                    <td>ELEMENT On/Off System, angle-seat valve</td>
                  </tr>
                  <tr>
                    <td>8802YC</td>
                    <td>Classic Continuous System, angle-seat control valve</td>
                  </tr>
                  <tr>
                    <td>8802YG</td>
                    <td>ELEMENT Continuous System, angle-seat control valve</td>
                  </tr>
                  <tr>
                    <td>8803YA</td>
                    <td>Classic On/Off System, angle-seat valve </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Ball Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2651</td>
                    <td>
                      Quarter-Turn Ball Valve Systems for normal and slightly
                      aggressive fluids
                    </td>
                  </tr>
                  <tr>
                    <td>2654</td>
                    <td>
                      Quarter-Turn Ball Valve Systems for normal and slightly
                      aggressive fluids
                    </td>
                  </tr>
                  <tr>
                    <td>2657</td>
                    <td>
                      Quarter-Turn Ball Valve Systems for highly aggressive and
                      ultra-pure fluids
                    </td>
                  </tr>
                  <tr>
                    <td>2658</td>
                    <td>
                      Quarter-Turn Ball Valve, Pneumatically Operated, for
                      highly aggressive and pure fluids
                    </td>
                  </tr>
                  <tr>
                    <td>8804</td>
                    <td>
                      2/2 and 3/2-way ball valve with electric rotary actuator,
                      ball valve in plastic or stainless steel, DN 10-65
                    </td>
                  </tr>
                  <tr>
                    <td>8805</td>
                    <td>
                      2/2 and 3/2-way ball valve with pneumatic rotary actuator,
                      ball valve in stainless steel or brass, nickel-plated
                    </td>
                  </tr>
                  <tr>
                    <td>TKU001</td>
                    <td>2/2-way compact flange ball valve, DN15 -DN150</td>
                  </tr>
                  <tr>
                    <td>TKUXXX</td>
                    <td>
                      2 and 3-way Ball Valves, TKU001, TKU002, TKU003, 2/2-or
                      3/2-way ball valve, manually operated, body material brass
                      and stainless steelDN 6-100
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Butterfly Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2671</td>
                    <td>
                      2-way Butterfly valves, intermediate flange mounting,
                      manually operated/automable, cast iron body, DN25 to 300
                    </td>
                  </tr>
                  <tr>
                    <td>2672</td>
                    <td>
                      2-Way Butterfly Valve, Pneumatically Operated, Cast Iron
                      Body, Stainless Steel Disc, DN 50-100
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Diaphragm Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2030</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Valve with
                      Plastic Body, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>2031</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Valve with
                      Stainless Steel Body, DN 4-100
                    </td>
                  </tr>
                  <tr>
                    <td>2031K</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Valve with
                      Cold-Formed Stainless Steel Body, DN 8-100
                    </td>
                  </tr>
                  <tr>
                    <td>2032</td>
                    <td>
                      T Valve, Pneumatically Operated, Stainless Steel Block
                      Material, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>2033</td>
                    <td>
                      Tank Bottom Valve, Pneumatically Operated, Stainless Steel
                      Body, DN 8-100
                    </td>
                  </tr>
                  <tr>
                    <td>2034</td>
                    <td>
                      Tandem valve, welded valve configuration, fully integrated
                      in Bürkert's Process Control Systems, Orifice DN8-100
                    </td>
                  </tr>
                  <tr>
                    <td>2035</td>
                    <td>
                      Multiway Multiport Diaphragm Valve, pneumatically operated
                    </td>
                  </tr>
                  <tr>
                    <td>2103</td>
                    <td>
                      2/2-way Diaphragm Valve with stainless steel design, weld
                      end or clamp connection, DN 4-25. Combinable as On/Off
                      System Type 8801-DF or Continuous System Type 8802-DF
                    </td>
                  </tr>
                  <tr>
                    <td>2730</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Control Valve
                      with Plastic Body, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>2731</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Control Valve
                      with Stainless Steel Body, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>2731K</td>
                    <td>
                      2/2 Way Pneumatically Operated Diaphragm Control Valve
                      with Cold-Formed, Stainless Steel Body, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>3230</td>
                    <td>
                      2/2-way Diaphragm Valve with plastic body, pneumatically
                      operated, DN 12 and 15
                    </td>
                  </tr>
                  <tr>
                    <td>3232</td>
                    <td>
                      2-way Diaphragm Valve, with plastic body, manually
                      operated, DN 15-50
                    </td>
                  </tr>
                  <tr>
                    <td>3233</td>
                    <td>
                      2-way Diaphragm Valve, manually operated, Stainless Steel
                      Body, DN 8-100
                    </td>
                  </tr>
                  <tr>
                    <td>3233K</td>
                    <td>
                      2-way Diaphragm Valve, manually operated, Cold-Formed
                      Stainless Steel Body, DN 8-100{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>3234</td>
                    <td>
                      T Valve, Manually Operated, Stainless Steel Block
                      Material, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>3235</td>
                    <td>
                      Tank Bottom Valve, Manually Operated, Stainless Steel
                      Block Material, DN 15-100
                    </td>
                  </tr>
                  <tr>
                    <td>3236</td>
                    <td>
                      Multiway Multiport Diaphragm Valve, manually operated
                    </td>
                  </tr>
                  <tr>
                    <td>8801DB</td>
                    <td>Classic On/Off System, diaphragm valve</td>
                  </tr>
                  <tr>
                    <td>8801TA</td>
                    <td>Classic On/Off System, T-Valve</td>
                  </tr>
                  <tr>
                    <td>8801DG</td>
                    <td>Classic On/Off System, tank bottom valve </td>
                  </tr>
                  <tr>
                    <td>8801DF</td>
                    <td>ELEMENT On/Off System, diaphragm valve</td>
                  </tr>
                  <tr>
                    <td>8802DD</td>
                    <td>Classic Continuous System, diaphragm control valve</td>
                  </tr>
                  <tr>
                    <td>8802DC</td>
                    <td>Classic Continuous System, diaphragm control valve</td>
                  </tr>
                  <tr>
                    <td>8802DF</td>
                    <td>ELEMENT Continuous System, diaphragm control valve</td>
                  </tr>
                  <tr>
                    <td>8803DB</td>
                    <td>Classic On/Off System, diaphragm valve</td>
                  </tr>
                  <tr>
                    <td>8803TA</td>
                    <td>Classic On/Off System, T-valve</td>
                  </tr>
                  <tr>
                    <td>8803DG</td>
                    <td>Classic On/Off System, tank bottom valve</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flow & Shut-off Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>0498</td>
                    <td>
                      Double pilot controlled check valve for realising 5/3 way
                      function with all ports blocked
                    </td>
                  </tr>
                  <tr>
                    <td>TRG001</td>
                    <td>Control valves, shuttle valve</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Globe Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>0262</td>
                    <td>2/2-Way Valve, Air Operated</td>
                  </tr>
                  <tr>
                    <td>2002</td>
                    <td>
                      3/2 Way Globe Valve, DN 13-50, Pneumatically Operated,
                      Gunmetal Body with Threaded Ends
                    </td>
                  </tr>
                  <tr>
                    <td>2012</td>
                    <td>
                      2/2 Way Globe Valve, DN 10-100, Pneumatically Operated,
                      Cast Stainless Steel Body
                    </td>
                  </tr>
                  <tr>
                    <td>2101</td>
                    <td>
                      2/2-way Globe Valve with stainless steel design for media
                      up to +180°C, DN 13-50. Combinable as On/Off System Type
                      8801-GC
                    </td>
                  </tr>
                  <tr>
                    <td>2301</td>
                    <td>
                      2/2-way Globe Control Valve with stainless steel design
                      for media up to +180°C, DN 13-50. Combinable as Continuous
                      System Type 8802-GD
                    </td>
                  </tr>
                  <tr>
                    <td>2712</td>
                    <td>
                      2/2-Way Globe Control Valve, Pneumatically Operated, Cast
                      Stainless Steel Body, DN 10-100
                    </td>
                  </tr>
                  <tr>
                    <td>8750</td>
                    <td>
                      Flow rate Controller for gas from DN15 up to DN100,
                      including, in standard, a control valve and 2 pressure
                      transmitters. The complete system is delivered, mounted on
                      a pipe with flange connection and pre-wired.
                    </td>
                  </tr>
                  <tr>
                    <td>8801GA</td>
                    <td>Classic On/Off System, globe valve</td>
                  </tr>
                  <tr>
                    <td>8801GC</td>
                    <td>ELEMENT On/Off System, globe valve</td>
                  </tr>
                  <tr>
                    <td>8802GD</td>
                    <td>ELEMENT Continuous System, globe control valve</td>
                  </tr>
                  <tr>
                    <td>8802GB</td>
                    <td>Classic Continuous System, globe control valve</td>
                  </tr>
                  <tr>
                    <td>8803GA</td>
                    <td>Classic On/Off System, globe valve</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  NAMUR Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>0450</td>
                    <td>
                      3/2- 5/2-and 5/3 Way Spool Valve with threaded port or
                      Namur Interface
                    </td>
                  </tr>
                  <tr>
                    <td>0590</td>
                    <td>
                      3/2, 5/2 or 5/3-way Solenoid Valve for pneumatic systems
                    </td>
                  </tr>
                  <tr>
                    <td>5470</td>
                    <td>
                      3/2 and 4/2-way Solenoid Valve for pneumatics; 18 mm
                      breadth; orifice DN 4 ; QNn 300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6517</td>
                    <td>
                      5/2-way Solenoid Valve for pneumatics;10 mm breadth;
                      orifice DN 4; QNn 300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6519</td>
                    <td>
                      5/2 and 3/2-way Solenoid Valve for pneumatics,
                      servo-assisted, 32 mm wide, DN 6, 8 and 9, flow rate 900
                      and 1300 l/min. Deliverable in standard, EExm, EExi and
                      NAMUR
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pneumatic Pilot Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>6012</td>
                    <td>
                      3/2-Way Miniature Solenoid Valve, General Purpose, 0-10
                      bar, 0-140 psi
                    </td>
                  </tr>
                  <tr>
                    <td>6014</td>
                    <td>
                      3/2-Way Compact Solenoid Valve, General Purpose, DN
                      1.5-2.5mm, 0-16 bar, 1/16-5/64, 0-72 psi, Direct acting
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  MicroFluidics Proportional Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2822</td>
                    <td>
                      Direct-acting 2/2-way Proportional Valve, orifices 0.05 -
                      1.0 mm. Excellent dynamics and turn-down ratio
                    </td>
                  </tr>
                  <tr>
                    <td>2871</td>
                    <td>
                      Direct-acting 2/2-way standard proportional valve,
                      orifices 0.05 - 2.0 mm, control range 1:200, pipe
                      connection: flange, 1/8"
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pneumatic Singular Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>6106</td>
                    <td>
                      3/2-way direct acting rocker solenoid valve for
                      pneumatics; CNOMO and BURKERT sub-base; 0-10 bar; DN 0.9 /
                      1.2mm; 16mm width
                    </td>
                  </tr>
                  <tr>
                    <td>0590</td>
                    <td>
                      3/2, 5/2 or 5/3-way Solenoid Valve for pneumatic systems
                    </td>
                  </tr>
                  <tr>
                    <td>0450</td>
                    <td>
                      3/2- 5/2-and 5/3 Way Spool Valve with threaded port or
                      Namur Interface
                    </td>
                  </tr>
                  <tr>
                    <td>5411</td>
                    <td>
                      3/2 Way Solenoid Valve; Servo-Assisted, QNn 900l/min
                    </td>
                  </tr>
                  <tr>
                    <td>5413</td>
                    <td>
                      4/2 Way Solenoid Valve; Servo-Assisted, QNn 900 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>5420</td>
                    <td>
                      4/2 Way Solenoid Valve; Servo-Assisted, QNn 200l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6144</td>
                    <td>
                      3/2-way Flipper Solenoid Valve, direct acting, 0 - 10 bar,
                      Sub-base, for neutral gases and liquids
                    </td>
                  </tr>
                  <tr>
                    <td>6518</td>
                    <td>
                      3/2-way Solenoid Valve for pneumatics, servo-assisted, 32
                      mm wide, DN 8, flow rate 1300 l/min. Deliverable in
                      standard, EExm and EExi
                    </td>
                  </tr>
                  <tr>
                    <td>6519</td>
                    <td>
                      5/2 and 3/2-way Solenoid Valve for pneumatics,
                      servo-assisted, 32 mm wide, DN 6, 8 and 9, flow rate 900
                      and 1300 l/min. Deliverable in standard, EExm, EExi and
                      NAMUR
                    </td>
                  </tr>
                  <tr>
                    <td>6510</td>
                    <td>
                      3/2 way valves for pneumatics, extenable, Flow rates: 130
                      l/min; 10mm wide, EX optional
                    </td>
                  </tr>
                  <tr>
                    <td>6511</td>
                    <td>
                      Multi-Way Valves for Pneumatics; Flow rates:130 l/min,
                      extendable, 10 mm wide;EX
                    </td>
                  </tr>
                  <tr>
                    <td>6516</td>
                    <td>
                      3/2-way Solenoid Valve for pneumatics; 32 mm breadth;
                      orifice DN 8; QNn 1.300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6517</td>
                    <td>
                      5/2-way Solenoid Valve for pneumatics; width coil 32mm,
                      DN8, QNn 1300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6524</td>
                    <td>
                      3/2-way and 2 x 3/2-way Solenoid Valve for pneumatics; 10
                      mm breadth; orifice DN 4; QNn 300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6525</td>
                    <td>
                      5/2-way Solenoid Valve for pneumatics; 10 mm breadth;
                      orifice DN 4; QNn 300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6526</td>
                    <td>
                      3/2-way Solenoid Valve for pneumatics; 16 mm breadth;
                      orifice DN 6; QNn 700 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>6527</td>
                    <td>
                      5/2-way Solenoid Valve for pneumatics; 16 mm breadth;
                      orifice DN 6; QNn 700 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>5470</td>
                    <td>
                      3/2 and 4/2-way Solenoid Valve for pneumatics; 18 mm
                      breadth; orifice DN 4 ; QNn 300 l/min
                    </td>
                  </tr>
                  <tr>
                    <td>0460</td>
                    <td>
                      5/2-pulse valve or 5/3-way solenoid valve for pneumatic
                      systems
                    </td>
                  </tr>
                  <tr>
                    <td>0461</td>
                    <td>
                      5/2-pulse valve or 5/3-way solenoid valve for pneumatic
                      systems
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  MicroFluidics Solenoid Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>6124</td>
                    <td>
                      2/2 or 3/2 way direct acting flipper solenoid valve with
                      isolating diaphragm; ultra low dead volume; DN 0.6mm;
                      vacuum up to 3bar; fast response time; 11mm width
                    </td>
                  </tr>
                  <tr>
                    <td>6126</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve with
                      isolating diaphragm; DN 0.8mm; vacuum up to 6bar; 16mm
                      width
                    </td>
                  </tr>
                  <tr>
                    <td>0330A</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; manufactured under
                      clean-room conditions, all wetted parts are cleaned from
                      organic and inorganic substances; very low leakage; direct
                      acting; vacuum – 10 bar; DN 2 up to 4mm; G1/4“
                    </td>
                  </tr>
                  <tr>
                    <td>6144</td>
                    <td>
                      3/2-way Flipper Solenoid Valve, direct acting, 0 - 10 bar,
                      Sub-base, for neutral gases and liquids
                    </td>
                  </tr>
                  <tr>
                    <td>6624</td>
                    <td>
                      2/2- and 3/2-way Rocker Solenoid Valve for analytical
                      applications. Medium separation for aggressive fluids. DN
                      0.8 or DN 1.6 with pressure range vacuum to 2 bar. 10 mm
                      width.
                    </td>
                  </tr>
                  <tr>
                    <td>6650</td>
                    <td>
                      2/2 and 3/2 Way Flipper Solenoid Valve for Analytical
                      Applications, only 4.5 mm wide,medium isulation, for
                      aggressive fluids, direct acting, vacuum up to 7 bar,
                      short response times
                    </td>
                  </tr>
                  <tr>
                    <td>6626</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve for
                      analytical applications; DN 3.0 mm; vacuum up to 2 bar; 16
                      mm width; medium separation
                    </td>
                  </tr>
                  <tr>
                    <td>6628</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve with
                      isolating diaphragm; DN 2.0 or 3.0 mm; vacuum up to 5 bar;
                      22 mm width; back pressure tightness; minimal internal
                      volume
                    </td>
                  </tr>
                  <tr>
                    <td>0121</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; direct acting; 0#4 bar; DN
                      4 up to 8mm; G1/4#, G3/8#
                    </td>
                  </tr>
                  <tr>
                    <td>0124</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; plastic body, 0#10 bar; DN
                      3 up to 5mm; G1/4#
                    </td>
                  </tr>
                  <tr>
                    <td>0127</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; plastic body, 0#10 bar; DN
                      3 up to 5mm; G1/4#
                    </td>
                  </tr>
                  <tr>
                    <td>0330</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; 0 - 16 bar; DN 2 up to
                      4mm; G1/4
                    </td>
                  </tr>
                  <tr>
                    <td>0331</td>
                    <td>
                      2/2 or 3/2 way direct acting pivoted armature solenoid
                      valve with isolating diaphragm; 0 # 16 bar; DN 2 up to
                      4mm; sub-base mounting
                    </td>
                  </tr>
                  <tr>
                    <td>6604</td>
                    <td>
                      2/2 or 3/2 way direct acting flipper solenoid valve with
                      isolating diaphragm; ultra low dead volume; DN 0.6mm;
                      vacuum up to 3bar; fast response time; 11mm width
                    </td>
                  </tr>
                  <tr>
                    <td>6606</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve with
                      isolating diaphragm; DN 1.5 or 1.6mm; vacuum up to 2bar;
                      16mm width
                    </td>
                  </tr>
                  <tr>
                    <td>6624</td>
                    <td>
                      2/2- and 3/2-way Rocker Solenoid Valve for analytical
                      applications. Medium separation for aggressive fluids. DN
                      0.8 or DN 1.6 with pressure range vacuum to 2 bar. 10 mm
                      width.
                    </td>
                  </tr>
                  <tr>
                    <td>6626</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve for
                      analytical applications; DN 3.0 mm; vacuum up to 2 bar; 16
                      mm width; medium separation
                    </td>
                  </tr>
                  <tr>
                    <td>6628</td>
                    <td>
                      2/2 or 3/2 way direct acting rocker solenoid valve with
                      isolating diaphragm; DN 2.0 or 3.0 mm; vacuum up to 5 bar;
                      22 mm width; back pressure tightness; minimal internal
                      volume
                    </td>
                  </tr>
                  <tr>
                    <td>6650</td>
                    <td>
                      2/2 and 3/2 Way Flipper Solenoid Valve for Analytical
                      Applications, only 4.5 mm wide,medium isulation, for
                      aggressive fluids, direct acting, vacuum up to 7 bar,
                      short response times
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Proportional Valves
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>2822</td>
                    <td>
                      Direct-acting 2/2-way Proportional Valve, orifices 0.05 -
                      1.0 mm. Excellent dynamics and turn-down ratio
                    </td>
                  </tr>
                  <tr>
                    <td>2861</td>
                    <td>
                      Direct-acting 2/2-way basic proportional valve, orifices
                      0.8 - 2.0 mm, control range 25:1
                    </td>
                  </tr>
                  <tr>
                    <td>2863</td>
                    <td>
                      Direct acting 2/2 way basic proportional valve, orifices
                      0.8 - 4.0 mm, control range 25:1
                    </td>
                  </tr>
                  <tr>
                    <td>2865</td>
                    <td>
                      Direct-acting 2/2-way basic proportional valve, orifices 2
                      - 8 mm, control range 25:1
                    </td>
                  </tr>
                  <tr>
                    <td>2871</td>
                    <td>
                      Direct-acting 2/2-way standard proportional valve,
                      orifices 0.05 - 2.0 mm, control range 1:200, pipe
                      connection: flange, 1/8"
                    </td>
                  </tr>
                  <tr>
                    <td>2873</td>
                    <td>
                      Direct acting 2/2 way standard proportional valve,
                      orifices 0.8 - 4.0 mm, control range 200:1, pipe
                      connection: flange, 1/8", 1/4"
                    </td>
                  </tr>
                  <tr>
                    <td>2875</td>
                    <td>
                      Direct-acting 2/2-way standard proportional valve,
                      orifices 2 - 8 mm, control range 1:200, pipe connection:
                      3/8", 1/2"
                    </td>
                  </tr>
                  <tr>
                    <td>2836</td>
                    <td>
                      Direct-acting 2/2-way Proportional Valve, orifices 3.0 -
                      12.0 mm
                    </td>
                  </tr>
                  <tr>
                    <td>6024</td>
                    <td>
                      Direct-acting 2/2-way low differential pressure
                      Proportional Valve, orifices 8.0 - 12.0 mm
                    </td>
                  </tr>
                  <tr>
                    <td>6223</td>
                    <td>
                      Servo-assisted 2/2-way high-flow Proportonal Valve,
                      orifices 10.0 - 20.0 mm
                    </td>
                  </tr>
                  <tr>
                    <td>8605</td>
                    <td>Control electronics for proportional valves</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
