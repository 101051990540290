import React from "react";

class Mission extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <>
        <div className="section-full  p-t80 p-b50 bg-white">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 col-md-12 m-b30">
                {/* TITLE START*/}
                <div className="section-head center wt-small-separator-outer">
                  <div className="wt-small-separator site-text-primary">
                    <div className="sep-leaf-left" />
                    <div>Vision &amp; Mission Statement</div>
                    <div className="sep-leaf-right" />
                  </div>
                  <h2>Building long-term relationships that last</h2>
                  <p>
                    Our worldwide presence ensures timeliness, cost efficiency
                    and compliance adherence required to ensure your timelines
                    are met. Serving with experience and expertise in multiple
                    industries, as one of Ontario's leading pump distributors!
                  </p>
                </div>
                {/* TITLE END*/}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Mission;
