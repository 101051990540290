import React from "react";

export default function FlowMeterContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Kobold Flow Meters</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          KOBOLD Messring GmbH, one of the leading international instrumentation
          engineering enterprises, was founded in 1980 by Dipl.-Ing. Klaus
          J.Kobold. His excellent engineering knowledge led to the enterprise
          soon establishing itself in the market place due it patentable
          technology, high quality products and superior service. The "KOBOLD"
          brand name soon became synonymous with quality and technological
          advance. In the following years, independent KOBOLD companies were
          established in numerous countries worldwide
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/kobold/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            The company's purpose is to develop, manufacture and sell
            instruments for monitoring, measuring and regulating the physical
            quantities of flow through, pressure, level and temperature.
          </p>
        </div>
      </div>
      <div>
        <h2>Flow Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Low Volume Flowmeters and Flow Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>KMI</td>
                    <td>Armored Low Flow Flowmeter/Switch/Transmitter</td>
                  </tr>
                  <tr>
                    <td>KSK</td>
                    <td>All Plastic Low Flow Flowmeter & Switch</td>
                  </tr>
                  <tr>
                    <td>KSR/SVN</td>
                    <td>Low Volume Flow Switch for Liquids or Gases</td>
                  </tr>
                  <tr>
                    <td>KDG/KDF</td>
                    <td>Low Volume Glass Tube Flowmeter</td>
                  </tr>
                  <tr>
                    <td>SWK</td>
                    <td>
                      Flowmeters and Switches KSV - Economical Micro Flowmeters
                    </td>
                  </tr>
                  <tr>
                    <td>KDK</td>
                    <td>
                      Low Volume/All-Metal Flowmeters KFR - Acrylic Flowmeters
                      for Liquids or Gases
                    </td>
                  </tr>
                  <tr>
                    <td>KDP</td>
                    <td>Miniature Armored Flowmeters & Switch</td>
                  </tr>
                  <tr>
                    <td>REL-6</td>
                    <td>Instrinsically Safe Relay/Power Supply</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Variable Area Flowmeters and Flow Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>VKP</td>
                    <td>Plastic Variable Area Flowmeters & Switches</td>
                  </tr>
                  <tr>
                    <td>KSM</td>
                    <td>
                      All Plastic Variable Area Flowmeters & Switches for
                      Liquids or Gases
                    </td>
                  </tr>
                  <tr>
                    <td>BVO</td>
                    <td>OEM Variable Area Flowmeters w/Switches</td>
                  </tr>
                  <tr>
                    <td>SV</td>
                    <td>Float Type Variable Area Flowmeters & Switches</td>
                  </tr>
                  <tr>
                    <td>SMN</td>
                    <td>Flow Switches for Liquids</td>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td>All Metal Flow Switches for Liquids or Gases</td>
                  </tr>
                  <tr>
                    <td>SM</td>
                    <td>
                      High Pressure All-Metal Variable Area Flowmeters &
                      Switches
                    </td>
                  </tr>
                  <tr>
                    <td>KDV</td>
                    <td>High Accuracy Glass Tube Rotameter</td>
                  </tr>
                  <tr>
                    <td>VKA</td>
                    <td>OEM Viscosity-Compensated Variable Area Flowmeters</td>
                  </tr>
                  <tr>
                    <td>VKG</td>
                    <td>
                      Viscosity Compensating Variable Area Flowmeters & Switches
                    </td>
                  </tr>
                  <tr>
                    <td>VKM</td>
                    <td>
                      All-Metal Viscosity Compensating Variable Area Flowmeters
                      and Switches
                    </td>
                  </tr>
                  <tr>
                    <td>BVB</td>
                    <td>
                      Manifold Valves for SV and VKG Variable Area Flowmeters
                    </td>
                  </tr>
                  <tr>
                    <td>KDM</td>
                    <td>All-Metal Armored Variable Area Flowmeters</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Paddle Type Flowmeters and Flow Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>DPT</td>
                    <td>Flow Meter</td>
                  </tr>
                  <tr>
                    <td>DW</td>
                    <td>Flow Meter</td>
                  </tr>
                  <tr>
                    <td>FPS</td>
                    <td>Flow Meter</td>
                  </tr>
                  <tr>
                    <td>LPS</td>
                    <td>Flow Switch</td>
                  </tr>
                  <tr>
                    <td>PPS</td>
                    <td>Paddle Flow Switch</td>
                  </tr>
                  <tr>
                    <td>PS</td>
                    <td>Paddle Flow Switch</td>
                  </tr>
                  <tr>
                    <td>PSR</td>
                    <td>Paddle Flow Switch</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Rotating Vane Flowmeters and Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>DPL</td>
                    <td>All Plastic Low Flow Paddle Wheel Sensor</td>
                  </tr>
                  <tr>
                    <td>DPM</td>
                    <td>Pelton Wheel Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>DRS</td>
                    <td>OEM Turbine Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>DTK</td>
                    <td>Pelton Wheel Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>SFL</td>
                    <td>High Precision Turbine Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>KFF</td>
                    <td>Low Volume, Rotating Vane Flowmeter</td>
                  </tr>
                  <tr>
                    <td>DRH</td>
                    <td>Paddle Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>DRG</td>
                    <td>Paddle Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>DPE</td>
                    <td>Turbine Wheel Flowmeter</td>
                  </tr>
                  <tr>
                    <td>DRB</td>
                    <td>Turbine Wheel Flowmeter</td>
                  </tr>
                  <tr>
                    <td>DF</td>
                    <td>Paddle Flow Sensor With Frequency Output</td>
                  </tr>
                  <tr>
                    <td>DFT</td>
                    <td>Compact Paddle Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>EDM</td>
                    <td>Battery Powered Rate Meter & Totalizer</td>
                  </tr>
                  <tr>
                    <td>TUR</td>
                    <td>All Plastic Turbine Flow Sensor</td>
                  </tr>
                  <tr>
                    <td>OVZ</td>
                    <td>Economical Oval-Gear Meter</td>
                  </tr>
                  <tr>
                    <td>OME</td>
                    <td>Helical Gear Flowmeter</td>
                  </tr>
                  <tr>
                    <td>ZDM</td>
                    <td>Positive Displacement Flowmeter</td>
                  </tr>
                  <tr>
                    <td>OMP</td>
                    <td>Positive Displacement Flowmeter</td>
                  </tr>
                  <tr>
                    <td>DRZ</td>
                    <td>Piston Flowmeter</td>
                  </tr>
                  <tr>
                    <td>WT</td>
                    <td>Mechanical Totalizer</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flow Measurement without Moving Parts
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>KEL</td>
                    <td>Heavy Duty Flow Meter or Switch</td>
                  </tr>
                  <tr>
                    <td>RCD</td>
                    <td>Ultra-Rugged Differential Pressure Flow Meter</td>
                  </tr>
                  <tr>
                    <td>RCM</td>
                    <td>
                      Direct Reading Scales Calibrated to Operating Conditions
                    </td>
                  </tr>
                  <tr>
                    <td>PMG</td>
                    <td>Magnetic Variable Area Flow Meters</td>
                  </tr>
                  <tr>
                    <td>DVZ</td>
                    <td>Vortex Variable Area Flow Meters and Switch</td>
                  </tr>
                  <tr>
                    <td>UV</td>
                    <td>Plastic Vortex Transmitter</td>
                  </tr>
                  <tr>
                    <td>MAS</td>
                    <td>Mass Variable Area Flow Meters for Gases</td>
                  </tr>
                  <tr>
                    <td>PME</td>
                    <td>Magnetic Inductive Flow Meter/Monitor</td>
                  </tr>
                  <tr>
                    <td>DMI</td>
                    <td>
                      Magnetic Inductive Variable Area Flow Meters for
                      conductive liquids and slurries
                    </td>
                  </tr>
                  <tr>
                    <td>KAL-K</td>
                    <td>
                      Thermal Flow Switch with LED Flow Trend Indication for
                      Liquids
                    </td>
                  </tr>
                  <tr>
                    <td>KAL-L</td>
                    <td>Thermal Air Flow Switch</td>
                  </tr>
                  <tr>
                    <td>DVK</td>
                    <td>Calorimetric Variable Area Flow Meters and Switch</td>
                  </tr>
                  <tr>
                    <td>KAL-D</td>
                    <td>Compact Thermal Flow Switch</td>
                  </tr>
                  <tr>
                    <td>KAL</td>
                    <td>
                      Temperature Compensating Thermal Flow Switch for Liquids
                    </td>
                  </tr>
                  <tr>
                    <td>KAL-A</td>
                    <td>Thermal Flow Sensor</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Visual Flow Indicators
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>DAA</td>
                    <td>
                      Flow Indicator with Rotor and Self Cleaning Mechanism
                    </td>
                  </tr>
                  <tr>
                    <td>DAF</td>
                    <td>Paddle Flow Indicator for Liquids</td>
                  </tr>
                  <tr>
                    <td>DKB</td>
                    <td>Ball Float Flow Indicator for Liquids or Gases</td>
                  </tr>
                  <tr>
                    <td>DKF</td>
                    <td>
                      Paddle Flow Indicator for Horizontal or Vertical
                      Installation
                    </td>
                  </tr>
                  <tr>
                    <td>INT</td>
                    <td>Integrating Ratemeter/Totalizer</td>
                  </tr>
                  <tr>
                    <td>MRT</td>
                    <td>Rate Meter, Totalizer & Batcher</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
