import React from "react";

export default function FlowMeterContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Burkert Flow Meters</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Burkert Canada Fluid Control Systems can be used wherever fluid media
          and gases need to be measured, controlled and regulated. Whether the
          application is filling, level, flow, pressure or temperature we have a
          solution and a uniquely comprehensive range of products to handle it,
          including solenoid, process and analytical valves, pneumatic
          actuation, sensors and controllers.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/burkert/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            For Burkert Canada it is not enough to simply offer individual
            products. Burkert Canada Fluid Control Systems aim is to provide
            complete system and application solutions that meet the specific
            needs of our customers. Tell us what you need and our engineers will
            find an appropriate solution using our vast experience and a wide
            range of services such as advice and engineering, installation,
            testing, and after-sales support.
          </p>
        </div>
      </div>
      <div>
        <h2>Flow Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Mass Flow Controller/Mass Flow Meter
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Mass flow gas measurement is independant of temperature and
                pressure, thus controlling a process gas can be stable and
                reproduceable. Mass flow controllers contain a PID-controller
                and can be driven through norm signal or field bus. Mass flow
                meters are without actuators and are only for measuring
                massflow.
                <br />
                <br />
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>8006</td>
                    <td>
                      Mass Flow Meter for Gases, "heavy-duty", full scale range
                      25 to 1500 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8626</td>
                    <td>
                      Mass Flow Controller for Gases, "heavy-duty", full scale
                      range 25 to 1500 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8700</td>
                    <td>
                      Mass Flow Meter for Gases, "capillary", full scale range
                      0.005 to 1 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8701</td>
                    <td>
                      Mass Flow Meter for Gases, "economy", full scale range
                      0.02 to 50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8702</td>
                    <td>
                      Mass Flow Meter for Gases, "industrial", full scale range
                      0.02 to 50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8703</td>
                    <td>
                      Mass Flow Meter for Gases, "OEM", full scale range 0.02 to
                      50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8705</td>
                    <td>
                      Mass Flow Meter (MFM) for gases, with capillary
                      technology, Bypass channel, full scale range 0.005 lN/min
                      to 15 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8710</td>
                    <td>
                      Mass Flow Controller for Gases, "capillary", full scale
                      range 0.01 to 1 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8711</td>
                    <td>
                      Mass Flow Controller for Gases, "economy", full scale
                      range 0.02 to 50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8712</td>
                    <td>
                      Mass Flow Controller for Gases, "industrial", full scale
                      range 0.02 to 50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8713</td>
                    <td>
                      Mass Flow Controller for Gases, "OEM", full scale range
                      0.02 to 50 lN/min (reference nitrogen)
                    </td>
                  </tr>
                  <tr>
                    <td>8715</td>
                    <td>
                      Mass Flow Controller (MFC) for gases, with capillary
                      technology, Bypass channel, full scale range 0.005 lN/min
                      to 15 lN/min (reference nitrogen)
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
