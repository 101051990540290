import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Promag Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Promag centrifugal magnetic-drive pumps have been engineered for
          efficient, reliable, zero leakage pumping in a wide range of chemical
          processes and OEM system applications. Promag pumps may look similar
          to competitive designs but the features and performance capabilities
          of Promag allows for retrofitting many existing systems without costly
          re-piping modifications.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/promag/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Concerned with the development, improvement, and implementation of
            integrated system of people, money, knowledge, information,
            equipment, energy, materials, analysis and syn thesis, as well as
            the mathematical, physical and social sciences together with the
            principle and methods of engineering design to specify, predict, and
            evaluate the results to be obtained from such systems or processes.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              With over 20 years serving the water industry, our team of
              professional and knowledgeable staff are capable of handling
              requests ranging from the industry standard to unique custom
              designs.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Promag</strong>
              <span>promagenviro.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Company Values</h2>
        <p>
          At Promag Enviro, we provide start to finish solutions that exceed
          expectations at every step of the process. Providing a quality system
          portfolio and unique service options for the water and waste water
          industry in North America, while continually promoting water treatment
          practices, which are both environmentally friendly and sustainable.
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Honesty</li>
              <li>Service</li>
              <li>Integrity</li>
              <li>Innovation</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/promag/promag-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  M Series
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                M Series seal-less mag-drive thermoplastic centrifugal pumps
                provide high reliability, with emphasis on optimum chemical
                compatibility. Interchangeable impellers provide flexibility for
                precisely matching head and flow capacities. PROMAG can be used
                in a wide range of acids and alkaline solutions up to
                temperatures of 200°F.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  MV Series
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                MV Series vertical seal-less mag-drive thermoplastic centrifugal
                pumps, ideally suited for filtration & spray systems, provide
                high reliability for intank and sump applications. The "MV"
                mag-drive liquid end is a true seal-less pump, free of lip or
                labyrinth seals. The hermetically sealed column ensures trouble
                free operation-complete isolation of the motor from the process
                liquid prevents internal motor corrosion and/or product
                contamination. Interchangeable impellers provide process
                flexibility for precisely matching head and flow capacities.
                Also, the standard enclosed centrifugal impeller design delivers
                maximum efficiencies, eliminating the need for repellers that
                increase power requirements and reduce performance.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  SR Series
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                SR Series seal-less mag-drive alloy centrifugal pump designs
                meet zero emission requirements in a very cost effective manner,
                without compromising reliability. Rugged containment shells
                provide maximum breach resistance for a zero leakage process,
                even during system upsets. Interchangeable impellers provide
                flexibility by precisely matching head and flow capacities.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  ST Series
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                ST Series pumps are manufactured with a valute casing designs
                for optimum efficiency and higher head. Oversized internal
                components are built to withstand maximum axial and radialshaft
                loads. Heavy casing wall thickness adds strenght, and
                temperature capability. Magnetic couplings provides high torque
                with low mass of internal magnet to resist uncoupling and reduce
                bearing wear.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
