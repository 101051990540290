import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Prosser Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          PROSSER® portable submersible pumps are the most cost-effective choice
          for efficient, reliable dewatering. More than 100 high head and high
          volume models are available. Prosser® pumps feature slimline design to
          fit in the smallest areas.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/prosser/BP-800x500.png")}
            alt=""
          />
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Portable Electric Submersible Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                If you need unwanted water removed, there is a Prosser® portable
                electric submersible pump that can do the job.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
