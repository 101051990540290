import React from "react";

export default function CouplingContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Lovejoy Couplings</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Lovejoy Couplings created the industry standard Jaw-style coupling in
          1927, and were the first to incorporate rubber-in-compression as a
          power transmission concept. Since then, Lovejoy Couplings has emerged
          as an international market leader, and today, the Lovejoy Couplings
          brand is recognized around the world as the premier supplier of
          flexible shaft couplings and other power transmission components.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/lovejoy/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Lovejoy Couplings products are manufactured to the most stringent
            international standards, which including AGMA, ANSI, SAE, DIN, JIS,
            and Imperial. They are also an accepted supplier of products that
            match military specifications.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>Where The World Turns For Couplings</p>
            <div className="p-t15 text-uppercase">
              <strong>Lovejoy Couplings</strong>
            </div>
          </blockquote>
        </div>
      </div>
      <div>
        <h2>Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Jaw Type Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                The Jaw Type couplings from Lovejoy are offered in the
                industry’s largest variety of stock bore/keyway combinations.
                These couplings require no lubrication and provide highly
                reliable service for light, medium, and heavy duty electrical
                motor and internal combustion power transmission applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Jaw In Shear Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Jaw In-Shear Coupling is non-fail safe, and thus the
                coupling acts as a fuse to prevent equipment damage in the event
                of torque overloads. Instead of the spider being compressed in
                between the interlocked jaws of each hub, our In-Shear coupling
                has the hubs drawn apart with the jaws aligned axially. The
                wider spider is then in a shear-plane.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Curved Jaw Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                As the premier manufacturer of couplings in the world, Lovejoy
                offers the full line of the European standard jaw coupling.
                Supplied and in use around the world, Lovejoy’s curved jaw
                couplings can meet your every curved jaw need.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  S-Flex Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The simple design of the S–Flex coupling ensures ease of
                assembly and reliable performance. No special tools are needed
                for installation or removal. The S–Flex couplings can be used in
                a wide variety of applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Motion Control Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The selection process for determining the proper Motion Control
                coupling starts with selecting the coupling design that best
                addresses the application requirements. The Lovejoy Motion
                Control Coupling Quick Reference Chart of the Motion Control
                catalog, provides a method of weighing performance
                characteristics of the Beam, Bellows, Mini-Disc, Oldham,
                Mini-Soft, Curved Jaw, and Mini-Jaw couplings. Each coupling is
                compared side by side in critical categories such as: material,
                torque, torsional stiffness, bore capacity, maximum RPM,
                misalignment capacity, maximum temperature and moment of
                inertia.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Gear Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy offers a variety of designs and models in its gear
                coupling family. From standard, off-the-shelf stock to new, high
                speed, special designs-Lovejoy can satisfy your gear coupling
                needs.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  High Performance Gears
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy High Speed and Engineered Special Gear Couplings satisfy
                a specific engineering need for custom coupling designs that
                require additional engineering. While standard components do
                exist, the unique requirements of individual applications will
                customize a coupling design. (For stock gear couplings, please
                see our Gear Couplings product page.)
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Grid Type Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Lovejoy Grid coupling reduces vibration by as much as 30%,
                and cushions shock loads to safeguard your driving and driven
                equipment. The flexible nature of the spring-like grid absorbs
                impact energy by spreading it out over time, thus reducing the
                magnitude of the peak loads. This is possible because of the
                progressive contact that occurs between the curved profile of
                the hub teeth and the flexible grid. Therefore, as the load
                increases, more of the tooth comes into contact with the grid,
                thus supplying superior protection and supreme performance.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Disc Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy takes great pride in being able to offer a wide variety
                of superior disc coupling products in models designed to meet
                the application needs of today’s worldwide power transmission
                market.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Torsional Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy offers a wide range of torsional couplings engineered to
                solve torsional vibration problems found in diesel engine driven
                equipment and other applications where torsional vibrations are
                prevalent. These include all internal combustion engines,
                reciprocating pumps and compressors, as well as variable
                frequency drives (VFD).
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Specialty Couplings
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Specialty couplings to meet a wide variety of user needs.
                Deltaflex, Uniflex, Saga, Rigid Sleeves, & Shaft Collars.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Universal Joints
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy has been manufacturing industrial universal joints for
                over 50 years. Our industrial universal joint product line is
                well established and provides you with a wide range of standard
                and specialized products. The shape of the yoke is a special
                feature which results in exceptionally high strength, yet allows
                full, free movement of the joint. This accounts for the high
                horsepower capacity at high speeds.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThirteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Variable Speed Drives
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThirteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Variable speed drives are needed because many applications are
                not run at the same speed all of the time, due to the
                surrounding conditions. Revolutions per minute (RPM) of the
                driven shaft need to be increased or decreased when there are
                changes in load conditions, application requirements, or other
                circumstances.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFourteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Shaft Locking Devices
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFourteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Lovejoy Shaft Locking Devices connect hubs solidly to shafts,
                using a keyless mechanical interference fit, to transmit torque
                or to withstand axial thrust. This mechanical interference fit
                utilizes screw tension in the Shaft Locking Device, converted
                into radial pressure via an inclined plane. This pressure
                expands the Shaft Locking Device to eliminate the gap between
                the hub and the shaft. The Shaft Locking Device uses the
                friction bond between the Shaft Locking Device and the shaft/hub
                to create a zero backlash connection. This connection is easily
                releasable to remove the mechanical interference fit.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
