import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Caster Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Caster Pump (WARRENDER, LTD.) specializes in technically advanced,
          hermetically sealed pumping systems. Caster seal-less mag-drive pumps
          are available in 18 designs with over 250 basic models. Caster is
          devoted to the production of seal-less magnetically coupled pumps.
          These Caster seal-less mag-drive pumps are built to withstand the most
          severe process conditions.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/caster/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            The latest WARRENDER sealless mag-drive pump designs of 20 different
            series (over 250 basic models) replace earlier Caster Pumps Division
            configurations (e.g., MCA, MCH, MC-SP, MTA, MT, MT-SP, MPA, MP).
            These designs include the WMCA alloy centrifugal ISO-2858, API-685,
            ANSI-B73.1, WMTA alloy regenerative turbine, WMRA alloy rotary vane,
            WMCP thermoplastic centrifugal, WMTP thermoplastic regenerative
            turbine, and WMRP thermoplastic rotary vane. WARRENDER, LTD allows
            for an easy transition from earlier Caster Pump designs to our new
            seal-less magnetic pump designs.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              We will stand by our customers with a performance guarantee for
              approved applications, combined with our one-year product
              warranty.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>WARRENDER</strong>
              <span>warrender.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Benefits</h2>
        <p>
          Benefit from partnering with Warrender as our pumps are precision
          engineered to meet the demanding applications in a wide range of
          industrial sectors. Gain a good return on your investment as our pumps
          are:
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>
                Offered in industry standards include API-610, API-685,
                ISO-2858, ANSI-B73.1 and Vertical Turbine.
              </li>
              <li>
                Available in Seal-less Magnetic Drive, Canned Motor and
                Mechanical Seal configurations.
              </li>
              <li>
                Fabricated at WARRENDER Pump factories that meet stringent
                ISO-9001 quality standards with a culmination of many decades of
                pump engineering, technical expertise and knowhow.
              </li>
              <li>
                Tested comprehensively for any fault or inefficiency before
                being shipped to customers’ doorsteps. WARRENDER’s test
                facilities are among the most formidable in the pump industry.
              </li>
              <li>
                WARRENDER Pumps are backed by both a 12/18 month Warranty and a
                Performance Guarantee for all approved applications.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/caster/caster-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                The WARRENDER WMCA pumps are magnetically-driven, heavy-duty,
                centrifugal process designs, capable of unmatched performance
                and reliability, even in the most arduous of applications. The
                substantial construction and zero-leakage design of WMCA
                centrifugals ensure maximum safety and full compliance with
                toxic emissions regulations. WMCA pumps are engineered to meet
                ANSI, ISO and API standards, and are fully customizable to your
                exact process specifications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Rotary Vane Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                WARRENDER, LTD produces the technically-superior WMRA pump range
                that is unique in both design and operation. The WARRENDER WMRA
                rotary vane seal-less mag-drive pumps are suited for low flow –
                high pressure applications, high-pressure systems or where
                metering is required.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Regenerative Turbine Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The WARRENDER WMTA magnetically-driven regenerative turbine pump
                is the ideal solution for low-flow / high head applications
                where zero-leakage is specified to meet toxic emissions
                regulations and ensure maximum safety. WMTA magnetic turbine
                pump has the capacity to pump liquids containing up to 20%
                entrained gas, and are suitable for thin, non-lubricating
                liquids and / or high differential pressures.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
