import React from "react";
import { NavLink } from "react-router-dom";

class WhatWeDo5 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-gray what-we-do-section2">
          <div className="container">
            <div className="section-content what-we-do-content">
              <div className="row d-flex justify-content-center flex-wrap">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="whatWedo-media-section">
                    <div className="wt-media">
                      <img
                        src={require("./../../images/BP-500x614.png")}
                        alt=""
                      />
                    </div>
                    <div className="whatWedo-media-content text-white">
                      <div className="whatWedo-media-inner">
                        <h3>Located in Burlington, Ontario</h3>
                        <p>
                          We have 25+ years of experience with providing a wide
                          range of industrial pumps and accessories
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b40">
                  <div className="whatWedo-info-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>Contact</div>
                        <div className="sep-leaf-right" />
                      </div>
                      <h2>A Leading Pump Distributor in Canada</h2>
                      <p>
                        You can find our Head Office and Warehouse located at
                        <b>
                          {" "}
                          3325 North Service Rd. Unit 106, Burlington, Ontario,
                          Canada, L7N 3G2
                        </b>
                      </p>
                    </div>
                    {/* TITLE END*/}
                    <div className="wt-icon-card-outer">
                      <NavLink
                        to={"/contact/information"}
                        style={{ marginBottom: "20px" }}
                      >
                        <div className="wt-icon-card bg-white shadow">
                          <div className="wt-card-header">
                            <i className="flaticon-robotic-arm site-text-primary" />
                            <span className="title-style-2 site-text-secondry">
                              Request Information
                            </span>
                          </div>
                          <div className="wt-card-content">
                            <p>
                              We are open to answering any questions you may
                              have about us or our products. We will try to get
                              back to you as soon as possible.
                            </p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink to={"/contact/quote"}>
                        <div className="wt-icon-card bg-white shadow">
                          <div className="wt-card-header">
                            <i className="flaticon-repair site-text-primary" />
                            <span className="title-style-2 site-text-secondry">
                              Request Quote
                            </span>
                          </div>
                          <div className="wt-card-content">
                            <p>
                              Our sales team will be happy to speak with you so
                              that you can get a comprehensive breakdown of our
                              prices and services.
                            </p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-large-title title-style-2">
            <span>What we do</span>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo5;
