import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Barnes Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          The Barnes Pumps family of pump products provides versatility, high
          performance and unequaled value. For more than a century, design and
          specifying engineers, plumbing contractors and end-users including
          homeowners have relied on Barnes Pumps for reliability and durability.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/barnes/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            From mundane but essential applications like sewage and wastewater
            to decorative fountain pumps found in the town square, Barnes Pumps
            delivers innovative, cost-effective solutions with speed and
            accuracy.
          </p>
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Many Barnes pumps operate continuously for over 30 years before
              being replaced.
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Barnes</strong>
              <span>cranepumps.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Applications of Barnes Products</h2>
        <p>
          Engineers, plumbing contractors and homeowners have continued to
          depend on Barnes pumps for reliability and durability since the
          brands’ founding in 1895. From essential applications like sewage and
          wastewater to fountain pumps and revolutionary pressure sewer systems,
          Barnes pumps deliver innovative, cost-effective solutions supported by
          best-in-class service.
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Wastewater and Sewage</li>
              <li>Pressure Sewer</li>
              <li>Plumbing</li>
              <li>Dewatering</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/barnes/barnes-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Condensate Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Barnes Condensate Pumps are CSA certified and come in two
                models, BC-15 and BC-20S. These pumps are general purpose
                residential and commercial pumps that remove condensate when
                gravity drains are not available.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Portable Transfer Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes Portable Transfer pumps are designed to handle a wide
                variety of pumping applications such as draining water heaters,
                charging polyglycol lines for radiant heating, pressure
                boosting, draining pool covers and dewatering flooded basements.
                Barnes Transfer pumps are constructed of durable materials and
                include a garden hose attachment for convenient use.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  EcoTRAN™ System
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The EcoTRAN™ System preserves groundwater ecology by collecting
                and grinding residential sewage in an underground basin and
                transmitting the waste under pressure to a remote private or
                municipal waste treatment plant.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Effluent Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Effluent pumps are designed for handling sewage effluent in
                typical septic tank / effluent applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  End-Suction Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                These end-suction pumps are heavy duty, general purpose
                centrifugal pumps with heavy wall castings suitable for many
                commercial and industrial applications such as condenser,
                chilled, and hot water systems in HVAC and process secondary
                pumping.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Fountain Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes UL listed fountain pumps are the market leader in
                decorative water applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Grinder Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes grinder pumps are designed to reduce sewage particles to
                a finely ground slurry.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Self-Priming Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes and Burks self-priming pumps are designed to meet a broad
                range of general and special industrial applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Self-Priming Solids Handling Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes Pumps offer a broad range of engineered self-priming
                solids handling centrifugal pumps for a variety of light to
                heavy-duty wastewater applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Non-Clog Submersible Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes Non-Clog series of pumps are commonly found in
                applications involving sewage, effluent, wastewater processing
                and solids laden media.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Packaged Plumbing Systems
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes Packaged Plumbing Systems are designed to handle wastes
                from lavatories, laundry tubs, floor drains, water closets, etc.
                and will push the water from below ground up to where normal
                drainage will handle it.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pressure Sewer Systems
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes Pressure Sewer Systems offer a broad range of customized
                engineered pressure sewer basins with reliable and dependable 1
                to 7.5 hp grinder pumps in simplex and duplex configurations to
                the municipal and commercial markets.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThirteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sewage Ejectors
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThirteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes engineered sewage ejectors are available in 2" & 3"
                discharge are for residential and light commercial wastewater
                applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFourteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sump Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFourteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Barnes residential sump pumps feature low cost, high performance
                benefits from a rugged, heavy duty design.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFifteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Centrifugal Grinder Pumps Replacement "Core Unit" Package
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFifteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The reliability and longevity of centrifugal grinder pumps is
                available in a replacement "core unit" package that fits
                directly into E/One® 200 and 2000 Series basins.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSixteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Prosser® Portable Electric Submersible Pump
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSixteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                If you need unwanted water removed, there is a Prosser® portable
                electric submersible pump that can do the job.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeventeen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Submersible Pump Accessories
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeventeen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                A Complete selection of control panels, float switches, rail
                systems, basins, hatches and competitive adaptors is available.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
