import React from "react";

export default function SupplierContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Grundfos Pumps</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Grundfos pumps are made for providing the people of the world with
          water – day in and day out, and in an efficient and reliable way. And
          for any purpose, whether the water is used to satisfy thirst, irrigate
          fields, water animals, speed industrial processes, heat or cool
          buildings, or handle waste-water - Grundfos pumps available in Canada
          fromBurlington Pump.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/grundfos/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <blockquote className="m-a0 bg-gray p-a30">
            <i className="fa fa-quote-left font-20" />
            <p>
              Our purpose is to pioneer solutions to the world’s water and
              climate challenges and improve quality of life for people.​
            </p>
            <div className="p-t15 text-uppercase">
              <strong>Grundfos</strong>
              <span>grundfos.com</span>
            </div>
          </blockquote>
        </div>
      </div>
      <div className="service-provide">
        <h2>Core Values</h2>
        <p>
          Our purpose is supported by six core values that embody who we are.
          Every single day, our purpose and values guide us on how to act and
          behave.
        </p>
        <div className="row">
          <div className="col-md-6">
            <ul className="list-angle-right">
              <li>Sustainable</li>
              <li>Open & trustworthy</li>
              <li>Focused on people</li>
              <li>Independent</li>
              <li>Partnership</li>
              <li>Relentlessly ambitious</li>
            </ul>
          </div>
          <div className="col-md-6">
            <div className="wt-media m-b30">
              <img
                src={require("./../../images/suppliers/grundfos/grundfos-400x250.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>Pump Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Centrifugal Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Centrifugal Pumps section features: Peerless PV
                Series, Grundfos CM, CME Series, Grundfos LM and LP Series, Paco
                KP, KPV Series, Grundfos MTR, MTC, MTA, SPK and CRK Series and
                the Grundfos CHI Series
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Circulation Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Circulation Pumps section features: Grundfos UP
                Pumps, Grundfos UPS Pumps, Grundfos ALPHA Series, Grundfos
                MAGNASeries , Grundfos MAGNA3 Series, and the Grundfos COMFORT
                Series.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Dosing Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Dosing Pumps section features: SMART Digital Dosing
                Pumps, Grundfos DDI Series, Grundfos DME Digital Dosing Pump,
                Grundfos DMH Mechanical Hydraulic Dosing Pump and the Grundfos
                DMX Mechanical Dosing Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Other Pump Types
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Other Pump Types section features: Grundfos TP
                Versaflo, Grundfos CR Series, Peerless LaBour Taber Sump Pumps,
                and the Grundfos E-Pump Series.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Suction Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Suction Pumps section features: Peerless C, PE, F
                End Suction Pumps, PACO LC, LF, LCV End Suction Pumps, Peerless
                A and AE Suction Pump, PACO VSM, VSMS Vertical Suction Pumps,
                Grundfos HS Series End Suction Pumps and Grundfos Fire Pumps.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Wastewater Pumps
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Grundfos Wastewater Pumps section features: Peerless C, PE,
                F End Suction Pumps, PACO LC, LF, LCV End Suction Pumps,
                Peerless A and AE Suction Pump, PACO VSM, VSMS Vertical Suction
                Pumps, Grundfos HS Series End Suction Pumps and Grundfos Fire
                Pumps.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
