import React from "react";

export default function MixerContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Koflo Mixers</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          For over 25 years, Koflo Corporation has focused on providing the
          highest quality static mixers. Koflo has the design expertise and
          in-house manufacturing capability to deliver an effective mixing
          solution, time after time. Whatever you need to blend or mix, Koflo
          has a static mixer for your specific application. Whether it be clear
          PVC, stainless steel, ductile iron, carbon steel, fiberglass or Kynar,
          we build a static mixers to meet the line size, viscosity, and
          pressure specifications you require. With Koflo, you'll get
          consistently high quality mixers, responsive service, and unparalleled
          technical expertise.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/koflo/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Our static mixers are a series of geometric mixing elements fixed
            within a pipe (tube), which use the energy of the flow stream to
            create mixing between two or more fluids. Throughout the years Koflo
            has optimized the design of our static mixers to achieve the
            greatest amount of mixing with the lowest pressure loss possible.
            Whether your application involves low viscosity fluids, high
            viscosity fluids, fibrous materials, or just the need for a quick
            flash blend, we have a static mixer design available to optimize
            your blending process.
          </p>
        </div>
      </div>
      <div>
        <h2>Mixer Products</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Clear PVC Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                In response to a growing need for high quality PVC static mixers
                at a lower price, Koflo developed the Series 308 PVC Static
                Mixer. This unit is a clear PVC static mixer, which unlike other
                static mixers, allows for a visual inspection of the mixing
                process. All Series 308 static mixers are made in standard 6
                element and 12 element configurations. Additionally, all PVC
                static mixers are edge sealed to the inside of the housing. The
                advantages of edge sealing are twofold. Not only does edge
                sealing increase mixing efficiency, but this bonding method also
                increases the structural integrity of the entire mixer. All
                mixers come standard with male NPT threads. Sizes 3/8"- 2" are
                in stock for immediate delivery.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Stock Stainless Steel
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo carries a complete line of stainless steel static mixers,
                in stock for immediate delivery. Stainless steel static mixers
                in sizes 1/4"- 2" are available in both 304L & 316L stainless
                steel with either 6 or 12 mixing elements. All mixers are welded
                into a schedule 40 housing and come complete with male NPT
                threaded ends.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Flanged Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo produces a complete line of inline static industrial
                mixers made from industrial grade schedules 10 through 40 pipe,
                in sizes ranging from 3/8"- 60" diameter. All inline mixers are
                custom designed for each application. Koflo stocks all of the
                raw materials and can produce a flanged industrial mixer in as
                little as 1-2 days. Standard materials of construction include
                carbon steel, 304L and 316L stainless steel. Other alloys are
                available upon request.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Gray PVC Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo produces a complete line of industrial grade schedule 80
                PVC static mixers in sizes ranging from 1/2"- 20". Threaded
                static mixers from 1/2"- 2", with both 6 and 12 mixing elements,
                are in stock and available for immediate delivery. Koflo also
                stocks our most popular 1”- 6” flanged PVC mixers both with and
                without injection ports. Larger custom designed PVC mixers can
                be shipped in as little as 1-2 days. Size permitting, all static
                mixers are both hot air-welded and solvent-cemented into the
                housing. This method provides both a high strength bond and
                edge-seals the mixer to the wall to prevent liquid from "slip
                streaming" around the mixing elements.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Ductile Iron Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo manufactures 4" through 24" cement-lined ductile iron
                static mixers. All ductile iron static mixers utilize an
                ANSI/AWWA C115 Class 53, cement-lined, flanged housing with 316L
                stainless steel mixing elements. Each mixer assembly is secured
                in the housing using a unique retaining bar. This retaining bar
                will secure the mixer assembly, regardless of installation
                orientation. The exterior of the mixer is protected with a rust
                preventative bituminous coating.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSix5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sludge Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSix5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                In the past, several industries have typically avoided the use
                of static mixers as due to their tendency to plug or foul. In
                response to this problem, Koflo developed and patented a
                revolutionary static mixer that is void of pinch points or
                intersecting elements. The Series 365 sludge mixer is able to
                handle even the most fibrous slurries without plugging. Each
                mixer contains a series of alternating right and left hand
                elements, which blend the product stream with counter rotating
                vortices.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseSeven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  High Viscosity Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseSeven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                For high viscosity blending applications we recommend our
                helical design mixer. These high viscosity mixers are generally
                used in applications that have a Reynolds number below 500. This
                can be a result of either a low flow rate, a high viscosity, or
                a combination of both. This element design is available as an
                option throughout most of our product line.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEight5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sanitary Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEight5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo manufactures a complete line of food quality sanitary
                static mixers. All sanitary static mixers are constructed of
                316L stainless steel. The mixing elements are available in two
                design configurations. The standard blade design is for low to
                medium viscosity applications. The helical design is uniquely
                suited for high viscosity blending and/or folding applications.
                Each of these designs is available in 6 and 12 element
                configurations depending on flowrate and viscosity. The internal
                elements and housing have a minimum 20 Ra (#4) sanitary finish.
                Lower Ra finishes are available for ultra
                critical/pharmaceutical applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseNine5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Stratos Tube Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseNine5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                The Koflo Stratos Static Tube Mixer consists of a series of
                fixed alternating right and left hand helical mixing elements
                designed for mixing light viscosity to high viscosity
                components. Both the elements and housing are constructed of
                316L SS for superior performance. Mixing elements are fixed by
                either 316L stainless steel TIG welds (1/4", 3/8", 1/2" tube
                mixers) or a silver braze (3/16" tube mixer only).
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  PVDF Kynar Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo manufactures a full range of both threaded and flanged
                unpigmented chemical mixing Kynar static mixers. Threaded
                chemical mixers are available from 1/2" - 2" diameter, and are
                constructed of schedule 80 unpigmented Kynar. These mixers have
                solid Kynar mixing elements welded to the interior pipe wall.
                Both 6 element and 12 element configurations are in stock and
                available for immediate delivery. Flanged mixers have Kynar
                lined schedule 40 carbon steel housings ranging from 1"- 6"
                diameter. These mixers have solid Kynar mixing elements welded
                to the liner. The exterior of all Kynar lined mixers is coated
                with a DuPont Imron Urethane epoxy to protect against damage
                from aggressive environments.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseEleven5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  PFA Teflon® Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseEleven5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo manufactures a full range of both threaded and flanged
                Teflon® mixers. Threaded mixers are available from 1/2"- 1"
                diameter, and are constructed of schedule 80 Teflon® PFA. These
                mixers have solid Teflon® PFA mixing elements welded to the
                interior pipe wall. Flanged mixers are available from 1”- 6”,
                and are constructed of Teflon®-lined schedule 40 carbon steel.
                These mixers have elements that are available in two material
                configurations. Our standard Teflon® PFA-lined mixers come with
                solid Teflon® PFA mixing elements. For high flow velocities, we
                offer Teflon® PTFE lined mixers with Hastelloy C-276 mixing
                elements. This no cost option offers high corrosion resistance
                with greater mechanical strength. The exterior of all Teflon®
                lined mixers is coated with a DuPont Imron Urethane epoxy to
                protect against damage from aggressive environments.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwelve5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Fiberglass Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwelve5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Koflo offers a complete line of fiberglass static mixers for
                applications that require a high degree of corrosion resistance.
                Each mixer is constructed using the highest quality vinyl ester
                resin. This will ensure a long service life even under the
                harshest conditions. All exposed elements are edge sealed to the
                housing for increased strength and maximum mixing efficiency.
                (Note: Koflo does not recommend placement of a fiberglass mixer
                in a steel or ductile iron line. See Series 512). Fiberglass
                mixers are available in sizes ranging from 6”- 48" and in
                various pressure ratings.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThirteen5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Wafer Mixers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThirteen5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                For limited applications where there is not enough length to
                accommodate a standard Static Mixer, Koflo offers a wafer mixer
                utilizing a Series 365 non-clog mixing element. The wafer mixer
                element provides both counter-rotating vortices and back mixing
                to achieve blending in the customer’s adjacent downstream
                piping. Primarily for high flow/low viscosity blending, Koflo
                Wafer Static Mixers are manufactured in almost all of the
                materials as our standard static mixer product line. For most
                applications a standard static mixer is still preferable, but
                our wafer static mixer can be an effective alternate when
                designed properly for the correct flow and mixing constraints.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
