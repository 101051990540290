import React from "react";
import { NavLink } from "react-router-dom";

class Slider extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/rev-script-7.js");
  }
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <>
        <div className="slider-outer">
          <div
            id="welcome_wrapper"
            className="rev_slider_wrapper fullscreen-container"
            data-alias="goodnews-header"
            data-source="gallery"
            style={{ background: "#eeeeee", padding: 0 }}
          >
            <div
              id="welcome"
              className="rev_slider fullscreenbanner"
              style={{ display: "none" }}
              data-version="5.4.3.1"
            >
              <ul>
                {/* SLIDE 1 */}
                <li
                  data-index="rs-901"
                  data-transition="fade"
                  data-slotamount="default"
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed="default"
                  data-thumb="images/main-slider/slider7/BP-1920x960.png"
                  data-rotate={0}
                  data-fstransition="fade"
                  data-fsmasterspeed={300}
                  data-fsslotamount={7}
                  data-saveperformance="off"
                  data-title="Slide Title"
                  data-param1="Additional Text"
                  data-param2
                  data-param3
                  data-param4
                  data-param5
                  data-param6
                  data-param7
                  data-param8
                  data-param9
                  data-param10
                  data-description
                >
                  {/* MAIN IMAGE */}
                  <img
                    src={require("./../../images/main-slider/slider7/BP-1920x960.png")}
                    alt=""
                    data-lazyload={require("./../../images/main-slider/slider7/BP-1920x960.png")}
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgparallax={4}
                    className="rev-slidebg"
                    data-no-retina
                  />
                  {/* LAYERS */}
                  {/* LAYER NR. 1 [ for overlay ] */}
                  <div
                    className="tp-caption tp-shape tp-shapewrapper "
                    id="slide-901-layer-0"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['0','0','0','0']"
                    data-width="full"
                    data-height="full"
                    data-whitespace="nowrap"
                    data-type="shape"
                    data-basealign="slide"
                    data-responsive_offset="off"
                    data-responsive="off"
                    data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderColor: "rgba(0, 0, 0, 0)",
                      borderWidth: 0,
                    }}
                  ></div>
                  {/* LAYER NR. 3 [ for title ] */}
                  <div
                    className="tp-caption   tp-resizeme"
                    id="slide-901-layer-3"
                    data-x="['left','left','left','left']"
                    data-hoffset="[100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['220','220','200','200']"
                    data-fontsize="[80','70','60','38']"
                    data-lineheight="['80','70','60','38']"
                    data-width="['600','600','96%','96%']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[5,5,5,5]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 13,
                      whiteSpace: "normal",
                      fontWeight: 500,
                      color: "#fff",
                      borderWidth: 0,
                      fontFamily: '"Teko", sans-serif',
                    }}
                  >
                    Future technologies and innovations
                  </div>
                  {/* LAYER NR. 4 [ for paragraph] */}
                  <div
                    className="tp-caption  tp-resizeme"
                    id="slide-901-layer-4"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['140','140','140','140']"
                    data-fontsize="['20','20','20','16']"
                    data-lineheight="['30','30','30','22']"
                    data-width="['500','500','90%','90%']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 13,
                      fontWeight: 500,
                      color: "#fff",
                      borderWidth: 0,
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    At Burlington Pump, we don't just sell you pumps, we
                    engineer solutions.
                  </div>
                  {/* LAYER NR. 5 [ for see all service botton ] */}
                  <div
                    className="tp-caption tp-resizeme"
                    id="slide-901-layer-5"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['50','50','50','50']"
                    data-lineheight="['none','none','none','none']"
                    data-width="['300','300','300','300']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[ 
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{ zIndex: 13, textTransform: "uppercase" }}
                  >
                    <NavLink
                      to={"/contact/quote"}
                      className="site-button site-btn-effect"
                    >
                      Request a Quote
                    </NavLink>
                  </div>
                </li>
                {/* SLIDE 2 */}
                <li
                  data-index="rs-902"
                  data-transition="fade"
                  data-slotamount="default"
                  data-hideafterloop={0}
                  data-hideslideonmobile="off"
                  data-easein="default"
                  data-easeout="default"
                  data-masterspeed="default"
                  data-thumb="images/main-slider/slider7/BP-1920x960-1.png"
                  data-rotate={0}
                  data-fstransition="fade"
                  data-fsmasterspeed={300}
                  data-fsslotamount={7}
                  data-saveperformance="off"
                  data-title="Slide Title"
                  data-param1="Additional Text"
                  data-param2
                  data-param3
                  data-param4
                  data-param5
                  data-param6
                  data-param7
                  data-param8
                  data-param9
                  data-param10
                  data-description
                >
                  {/* MAIN IMAGE */}
                  <img
                    src={require("./../../images/main-slider/slider7/BP-1920x960-1.png")}
                    alt=""
                    data-lazyload={require("./../../images/main-slider/slider7/BP-1920x960-1.png")}
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgparallax={4}
                    className="rev-slidebg"
                    data-no-retina
                  />
                  {/* LAYERS */}
                  {/* LAYER NR. 1 [ for overlay ] */}
                  <div
                    className="tp-caption tp-shape tp-shapewrapper "
                    id="slide-902-layer-0"
                    data-x="['center','center','center','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['0','0','0','0']"
                    data-width="full"
                    data-height="full"
                    data-whitespace="nowrap"
                    data-type="shape"
                    data-basealign="slide"
                    data-responsive_offset="off"
                    data-responsive="off"
                    data-frames='[
                            {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      borderColor: "rgba(0, 0, 0, 0)",
                      borderWidth: 0,
                    }}
                  ></div>
                  {/* LAYER NR. 3 [ for title ] */}
                  <div
                    className="tp-caption   tp-resizeme"
                    id="slide-902-layer-3"
                    data-x="['left','left','left','left']"
                    data-hoffset="[100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['220','220','200','200']"
                    data-fontsize="[80','70','60','38']"
                    data-lineheight="['80','70','60','38']"
                    data-width="['600','600','96%','96%']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[5,5,5,5]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 13,
                      whiteSpace: "normal",
                      fontWeight: 500,
                      color: "#fff",
                      borderWidth: 0,
                      fontFamily: '"Teko", sans-serif',
                    }}
                  >
                    High-quality industrial products
                  </div>
                  {/* LAYER NR. 4 [ for paragraph] */}
                  <div
                    className="tp-caption  tp-resizeme"
                    id="slide-902-layer-4"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['140','140','140','140']"
                    data-fontsize="['20','20','20','16']"
                    data-lineheight="['30','30','30','22']"
                    data-width="['500','500','90%','90%']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{
                      zIndex: 13,
                      fontWeight: 500,
                      color: "#fff",
                      borderWidth: 0,
                      fontFamily: '"Poppins", sans-serif',
                    }}
                  >
                    Your primary source for industrial pumps, valves, flow
                    meters, mixers, heat-exchangers, and more.
                  </div>
                  {/* LAYER NR. 5 [ for see all service botton ] */}
                  <div
                    className="tp-caption tp-resizeme"
                    id="slide-902-layer-5"
                    data-x="['left','left','left','left']"
                    data-hoffset="['100','100','30','30']"
                    data-y="['bottom','bottom','bottom','bottom']"
                    data-voffset="['50','50','50','50']"
                    data-lineheight="['none','none','none','none']"
                    data-width="['300','300','300','300']"
                    data-height="['none','none','none','none']"
                    data-whitespace="['normal','normal','normal','normal']"
                    data-basealign="slide"
                    data-type="text"
                    data-responsive_offset="on"
                    data-frames='[ 
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]'
                    data-textalign="['left','left','left','left']"
                    data-paddingtop="[0,0,0,0]"
                    data-paddingright="[0,0,0,0]"
                    data-paddingbottom="[0,0,0,0]"
                    data-paddingleft="[0,0,0,0]"
                    style={{ zIndex: 13, textTransform: "uppercase" }}
                  >
                    <NavLink
                      to={"/contact/information"}
                      className="site-button site-btn-effect"
                    >
                      Get in Touch
                    </NavLink>
                  </div>
                </li>
              </ul>
              <div
                className="tp-bannertimer tp-bottom"
                style={{ visibility: "hidden !important" }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Slider;
