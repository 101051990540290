import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AWLakeFlowMeterContent from "./AWLakeFlowMeterContent";
import BurkertFlowMeterContent from "./BurkertFlowMeterContent";
import FluxFlowMeterContent from "./FluxFlowMeterContent";
import KoboldFlowMeterContent from "./KoboldFlowMeterContent";

class ProductDetails extends React.Component {
  render() {
    return (
      <div className="col-lg-8 col-md-12">
        <Switch>
          <Route
            path="/products/flow-meters/aw-lake"
            component={AWLakeFlowMeterContent}
          />
          <Route
            path="/products/flow-meters/burkert"
            component={BurkertFlowMeterContent}
          />
          <Route
            path="/products/flow-meters/flux"
            component={FluxFlowMeterContent}
          />
          <Route
            path="/products/flow-meters/kobold"
            component={KoboldFlowMeterContent}
          />
          <Route path="/products/flow-meters">
            <Redirect to="/products/flow-meters/aw-lake" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default ProductDetails;
