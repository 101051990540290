import React from "react";

export default function PressureInstrumentContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Winters Instruments</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          Through numerous acquisitions and organic development, Winters
          Instruments has been transformed from a small factory to the global
          instrumentation manufacturer that it is today. Manufacturing
          efficiencies and engineering support to customers have become focused
          developments for Winters today and will continue into the future.
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/winters/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            Our comprehensive product lines of pressure gauges, thermometers,
            diaphragm seals, transmitters, switches and accessories are brought
            to market by an extensive network of distribution partners and are
            trusted by some of the world’s most recognized Fortune 500
            companies. Winters Instruments currently operates from 8 worldwide
            offices. In North America alone, Winters operates 3 stocking
            facilities, enabling distributors to access over $10 million worth
            of inventory.
          </p>
        </div>
      </div>
      <div>
        <h2>Pressure Instruments</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Premium Pressure Gauges
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                Winters Instruments Premium Series pressure gauges feature with
                the highest accuracy, (0.5%-1%), ANSI/ ASME Grade 1A/2A), offers
                the most durability and features a 5 year warranty. Stainless
                cases and stainless steel internals are common features in this
                series. Pressure gauges range from general use to specialty
                applications.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Quality Pressure Gauges
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Winters Instruments Quality Series pressure gauges offer a high
                accuracy (1%-3% ANSI/ ASME Grade 1A/Grade B), rugged-ness and
                features a 3 year warranty. Various case materials and brass
                internals are common in this series. Instruments range from
                general use to specialty applications and custom dials/ custom
                products are also available.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Pressure Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Winters Instruments offers a comprehensive line of pressure
                switches for various industrial applications. Winters’ pressure
                switches feature; aluminum alloy cases, stainless steel or
                carbon steel diaphragm, micrometer adjustable internal or
                external set-point adjustment. Each series of pressure switches
                are highly configurable to meet any requirement that you may
                have.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFour5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Thermometers
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFour5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Winters Instruments offers a wide range of direct and remote
                reading thermometers for various industrial applications.
                Accuracy ranges from 0.5% to 3% full scale. Winters’
                thermometers can be customized utilizing various custom dial
                scales and connections. Thermowells are also available in order
                to suit all the thermometers offered. In accordance with federal
                government standards, Winters offers a comprehensive line of
                alcohol hydrometers/ thermometers.
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseFive5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Diaphragm Seals
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseFive5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                Winters Instruments offers a wide range of diaphragm seals for
                various industrial applications.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
