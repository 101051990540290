import React from "react";

export default function PressureInstrumentContent() {
  return (
    <div className="section-content">
      {/* TITLE START */}
      <div className="text-left">
        <h2 className="wt-title">Kobold Instruments</h2>
      </div>
      {/* TITLE END */}
      <div className="wt-box services-etc m-b30">
        <p>
          KOBOLD Messring GmbH, one of the leading international instrumentation
          engineering enterprises, was founded in 1980 by Dipl.-Ing. Klaus
          J.Kobold. His excellent engineering knowledge led to the enterprise
          soon establishing itself in the market place due it patentable
          technology, high quality products and superior service. The "KOBOLD"
          brand name soon became synonymous with quality and technological
          advance. In the following years, independent KOBOLD companies were
          established in numerous countries worldwide
        </p>
        <div className="wt-media m-b30">
          <img
            src={require("./../../images/suppliers/kobold/BP-800x500.png")}
            alt=""
          />
        </div>
        <div className="wt-info">
          <p>
            The company's purpose is to develop, manufacture and sell
            instruments for monitoring, measuring and regulating the physical
            quantities of flow through, pressure, level and temperature.
          </p>
        </div>
      </div>
      <div>
        <h2>Pressure Instruments</h2>
        <div className="wt-accordion acc-bg-gray" id="accordion5">
          <div className="panel wt-panel">
            <div className="acod-head acc-actives">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseOne5"
                  data-parent="#accordion5"
                >
                  Gauges
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseOne5" className="acod-body collapse show bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>DRF22</td>
                    <td>Brass Bourdon Tube Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>DZF26</td>
                    <td>SS Pressure Gauges w/ Analog Output</td>
                  </tr>
                  <tr>
                    <td>MAN-SD</td>
                    <td>Digital Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>DSF26</td>
                    <td>Digital Pressure Gauges w/ Analog Output</td>
                  </tr>
                  <tr>
                    <td>DPF76</td>
                    <td>SS Diaphragm Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>DRM</td>
                    <td>Diaphragm Seal</td>
                  </tr>
                  <tr>
                    <td>DRF26</td>
                    <td>SS Bourdon Tube Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>Diaphragm Seal</td>
                    <td>
                      For paper industries, membrane diaphragm seals, Capsule
                      diaphragm seals
                    </td>
                  </tr>
                  <tr>
                    <td>DRF34</td>
                    <td>Brass Pressure Gauges w/ Switch</td>
                  </tr>
                  <tr>
                    <td>DRG26</td>
                    <td>SS Pressure Gauges w/ Switch</td>
                  </tr>
                  <tr>
                    <td>DRG64</td>
                    <td>6" Brass Bourdon Tube Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>PGS</td>
                    <td>Low Cost Pressure Gauges</td>
                  </tr>
                  <tr>
                    <td>KPH</td>
                    <td>Compact Pressure Switch</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseTwo5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Sensors
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseTwo5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>PDD</td>
                    <td>Digital Pressure Switch</td>
                  </tr>
                  <tr>
                    <td>PDA</td>
                    <td>Digital Pressure Transmitter</td>
                  </tr>
                  <tr>
                    <td>SEN</td>
                    <td>OEM Pressure Transmitter</td>
                  </tr>
                  <tr>
                    <td>AUF</td>
                    <td>Compact Local Electronic Display</td>
                  </tr>
                  <tr>
                    <td>KPW</td>
                    <td>Submersible Pressure Transducer</td>
                  </tr>
                  <tr>
                    <td>KPG</td>
                    <td>High Precision Pressure Transmitter</td>
                  </tr>
                  <tr>
                    <td>KPK</td>
                    <td>Compact High Precision Pressure Transducer</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="panel wt-panel">
            <div className="acod-head">
              <h4 className="acod-title">
                <a
                  data-toggle="collapse"
                  href="#collapseThree5"
                  className="collapsed"
                  data-parent="#accordion5"
                >
                  Switches
                  <span className="indicator">
                    <i className="fa fa-plus" />
                  </span>
                </a>
              </h4>
            </div>
            <div id="collapseThree5" className="acod-body collapse bg-gray">
              <div className="acod-content p-a15">
                <table>
                  <tr>
                    <th>
                      <i>Type</i>
                    </th>
                    <th>
                      <i>Description</i>
                    </th>
                  </tr>
                  <tr>
                    <td>PDD</td>
                    <td>Digital Pressure Switch</td>
                  </tr>
                  <tr>
                    <td>KPS/KAS</td>
                    <td>Pressure Switches</td>
                  </tr>
                  <tr>
                    <td>KPH</td>
                    <td>Compact Pressure Switch</td>
                  </tr>
                  <tr>
                    <td>KRT</td>
                    <td>Pressure Switches</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
