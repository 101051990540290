import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <>
      <div
        id="navbar"
        className={`nav-animation header-nav navbar-collapse collapse d-flex justify-content-center`}
      >
        <ul className="nav navbar-nav">
          <li>
            <NavLink to={"/"}>Home</NavLink>
          </li>
          <li>
            <NavLink to={"/products"}>Products</NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to={"/products/pumps"}>Industrial Pumps</NavLink>
              </li>
              <li>
                <NavLink to={"/products/boiler-controls"}>
                  Boiler & Level Controls
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/couplings"}>Couplings</NavLink>
              </li>
              <li>
                <NavLink to={"/products/flow-meters"}>Flow Meters</NavLink>
              </li>
              <li>
                <NavLink to={"/products/mixers"}>Industrial Mixers</NavLink>
              </li>
              <li>
                <NavLink to={"/products/pump-accessories"}>
                  Metering Pump Accessories
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/pressure-instruments"}>
                  Pressure Instruments
                </NavLink>
              </li>
              <li>
                <NavLink to={"/products/strainers"}>Strainers</NavLink>
              </li>
              <li>
                <NavLink to={"/products/valves"}>Valves</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to={"/markets"}>Markets</NavLink>
          </li>
          <li>
            <NavLink to={"/suppliers"}>Suppliers</NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to={"/contact"}>Contact</NavLink>
            <ul className="sub-menu">
              <li>
                <NavLink to={"/contact/information"}>
                  Information Request
                </NavLink>
              </li>
              <li>
                <NavLink to={"/contact/quote"}>Quote Request</NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
