import React from "react";
import { NavLink } from "react-router-dom";

class About extends React.Component {
  render() {
    return (
      <>
        <div className="section-full welcome-section-outer">
          <div className="welcome-section-top bg-white p-t80 p-b50">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="welcom-to-section">
                    {/* TITLE START*/}
                    <div className="left wt-small-separator-outer">
                      <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>Welcome to Burlington Pump Inc.</div>
                        <div className="sep-leaf-right" />
                      </div>
                    </div>
                    <h2>
                      Your First Source for Industrial Pumps and Accessories
                    </h2>
                    {/* TITLE END*/}
                    <ul className="site-list-style-one">
                      <li>QA & Testing, 100% Satisfaction Guarantee</li>
                      <li>
                        Unrivalled Workmanship, Professional and Qualified
                      </li>
                      <li>Large range of industrial pumps and accessories</li>
                    </ul>
                    <p>
                      Never visit more than one supplier to get the parts you
                      need again. When you team up with Burlington Pump, Inc.,
                      it’s easy to find exactly what you need under one roof.
                      We've been in the business for over 25 years, being a
                      trusted source for industrial pumps and accessories in
                      Canada.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="img-colarge2">
                    <div className="colarge-2 slide-right">
                      <img
                        src={require("../../images/BP-500x614.png")}
                        alt=""
                      />
                    </div>
                    <div className="colarge-2-1">
                      <img
                        src={require("../../images/BP-486x503-3.png")}
                        alt=""
                      />
                    </div>
                    <div className="since-year-outer2">
                      <div className="since-year2">
                        <span>Since</span>
                        <strong>1997</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
